import { Button, Col, Drawer, Form, Row, Typography } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from '../context/modalsSlice'
import { useEffect, useState } from 'react'
import { GetClients, GetExchangeRates, GetServices } from '../context/databaseContextHelpers'
import { getItemsAmounts } from '../functions/helpers'
import { CaretDoubleRight, X } from '@phosphor-icons/react'
import CreatePaymentForm from '../components/Forms/CreatePaymentForm'
import useSetContextCloseDrawers from '../context/setContext/SetContextCloseDrawers'
import { setData } from '../context/dataSlice'
import { addParamToUrl, removeParamFromUrl } from '../functions/UrlParams'
import { SuccessView } from '../components/UI/ResourceCreatedView'

export const CreatePaymentDrawer: React.FC = () => {
    const dispatch = useDispatch()
    const { team } = useSelector((state: any) => state.team)
    const { paymentModalVisible } = useSelector((state: any) => state.modals)
    const { services, clients, exchangeRates, recurringEventData, servicesLoaded, clientsLoaded } = useSelector(
        (state: any) => state.data,
    )
    const [form] = Form.useForm()
    const { payment, testmode } = useSelector((state: any) => state.data)
    const [loading, setloading] = useState(false)
    const [step, setStep] = useState(0)

    useEffect(() => {
        form.setFieldsValue({
            ...payment,
            periodicity: payment?.periodicity ?? 'month',
            currency: payment?.currency ?? 'MXN',
            payment_form: payment?.payment_form ?? '01',
            exchange: payment?.exchange ?? 1,
        })
        dispatch(setData({ item: 'custom_method_types', data: payment?.custom_method_types ?? [] }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payment])
    const setContextCloseDrawers = useSetContextCloseDrawers({ form })

    useEffect(() => {
        if (paymentModalVisible) {
            if (services.length === 0 && !servicesLoaded) {
                GetServices(dispatch, team.id)
            }

            if (clients.length === 0 && !clientsLoaded) {
                GetClients(dispatch, team.id, clientsLoaded)
            }

            if (Object.keys(exchangeRates).length === 0) {
                GetExchangeRates(dispatch)
            }

            addParamToUrl('open', 'payment')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentModalVisible])

    const Cancel = () => {
        removeParamFromUrl('open')
        dispatch(closeModal('paymentModalVisible'))
        setStep(0)
        dispatch(
            setData({
                item: 'payment',
                data: {},
            }),
        )
        dispatch(
            setData({
                item: 'recurringEventData',
                data: {},
            }),
        )
        dispatch(
            setData({
                item: 'custom_method_types',
                data: [],
            }),
        )
        setContextCloseDrawers()
    }

    const handleModalTitle = () => {
        if (payment?.from === 'recurringEvent') {
            return payment?.action === 'edit' ? `Editar recurrencia de pago` : 'Crear recurrencia de pago'
        }

        if (payment?.from === 'paymentLink') {
            return payment?.id ? 'Editar enlace de pago' : 'Crear enlace de pago'
        }

        return 'Solicitar pago'
    }

    const handleSuccessMessage = () => {
        const total = getItemsAmounts(payment?.items ?? []).totalString
        if (payment?.from === 'recurringEvent') {
            return recurringEventData?.id && payment?.action === 'edit'
                ? { title: 'Recurrencia actualizada', message: `Recurencia de pago actualizada correctamente` }
                : { title: 'Recurrencia creada', message: 'Recurrencia creada correctamente' }
        }

        if (payment?.from === 'paymentLink') {
            return payment?.id
                ? { title: 'Enlace de pago creado', message: `Enlace de pago por ${total} creado correctamente` }
                : { title: 'Enlace de pago creado', message: `Enlace de pago por ${total} creado correctamente` }
        }

        return {
            title: 'Pago solicitado',
            message: `La solicitud de pago por ${total} ha sido creada correctamente. ${
                payment?.emails?.length > 0 ? `La hemos enviado a: ` : ''
            }`,
        }
    }

    return (
        <Drawer
            title={
                <div style={{ position: 'relative', paddingBottom: '0px' }}>
                    <Typography.Text
                        className="smallheader"
                        style={{
                            position: 'absolute',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            paddingTop: '5px',
                        }}
                    >
                        {handleModalTitle()}
                    </Typography.Text>
                    <Row>
                        <Col xs={24} style={{ margin: 'auto' }}>
                            <Row justify="space-between" align="middle">
                                <X className="icon clickable" size={20} onClick={Cancel} />
                                <></>
                            </Row>
                        </Col>
                    </Row>
                </div>
            }
            closeIcon={<></>}
            onClose={Cancel}
            open={paymentModalVisible}
            width={'100%'}
            style={{ zIndex: 8000000, marginTop: testmode ? '32px' : '0px', paddingBottom: testmode ? '32px' : '0px' }}
        >
            <Row justify="center">
                <Col xs={23} lg={17}>
                    {step === 0 ? (
                        <CreatePaymentForm
                            form={form}
                            setStep={(step: number) => setStep(step)}
                            setloading={(l: boolean) => setloading(l)}
                            workWith={'payment'}
                        />
                    ) : step === 1 ? (
                        <SuccessView
                            resource={payment}
                            resourceType="payment"
                            title={handleSuccessMessage().title}
                            description={handleSuccessMessage().message}
                            tags={
                                !['recurringEvent', 'paymentLink'].includes(payment?.from) && payment?.emails?.length
                                    ? payment?.emails
                                    : []
                            }
                        />
                    ) : (
                        <></>
                    )}
                </Col>
            </Row>

            {step !== 1 && (
                <Row justify="center">
                    <Col xs={24} lg={17} style={{ marginTop: '20px' }}>
                        <Row justify="end">
                            <Button
                                loading={loading}
                                size="large"
                                type="primary"
                                htmlType="submit"
                                disabled={loading}
                                onClick={() => form.submit()}
                            >
                                {handleModalTitle()}
                                <CaretDoubleRight
                                    size={15}
                                    style={{ marginLeft: '5px' }}
                                    className=""
                                    weight="regular"
                                />
                            </Button>
                        </Row>
                    </Col>
                </Row>
            )}
        </Drawer>
    )
}
