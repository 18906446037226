import { Info } from '@phosphor-icons/react'
import { Button, Card, message, Row, Space, Tag, Typography } from 'antd'
import { doc, getDoc, getFirestore } from 'firebase/firestore'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { redirect, useNavigate, useSearchParams } from 'react-router-dom'
import { useAuth } from 'reactfire'
import { PricingPlan } from '../../../interfaces/PricingPlanDef'
import { setBillingAccount } from '../../context/billingAccountSlice'
import { setTeam } from '../../context/teamsSlice'
import { generateCode, preserveQueryParams, returnCurrencyValue } from '../../functions/helpers'
import FreePlansModal from '../../modals/FreePlansModal'
import PlanConfirmationModal from '../../modals/UpgradePlanConfirmationModal'
import { getFrontUrl, isStagingOrLocal } from '../Integrations/stripeHelpers'
import { usePostHog } from 'posthog-js/react'
import { trackEvent } from '../../analytics/helpers'

const featureTranslations: { [key: string]: string } = {
    includedDocuments: 'eventos',
    aditionalDocumentPrice: 'Evento adicional',
    seats: 'asientos',
    maxClients: 'clientes',
    selfBillingPortal: 'Portal de facturación propia',
    maxIntegrations: 'Integraciones',
    customerPortal: 'Portal de cliente',
    technicalSupport: 'Soporte técnico',
    apiAndWebhooks: 'API keys y Webhooks',
    customDomain: 'Dominio personalizado',
    multipleIssuerAccounts: 'Multi-cuentas de facturación',
    analyticsAndReports: 'Estadísticas e informes',
    dedicatedSupportChannel: 'Canal de soporte dedicado',
    supplierMode: 'Modalidad de proveedores',
    unlimited: 'Ilimitado',
}

interface PricingCardProps {
    plan: PricingPlan
    billingCycle: 'monthly' | 'annual'
    isRecommended?: boolean
    disabled?: boolean
    onSelect?: () => void
    isDowngrade?: boolean
}

const documentsOrder = ['includedDocuments', 'aditionalDocumentPrice', 'seats', 'maxClients', 'maxIntegrations']

const featureOrder = [
    'selfBillingPortal', // 0
    'customerPortal', // 1
    'technicalSupport', // 2
    'apiAndWebhooks', // 3
    'customDomain', // 4
    'multipleIssuerAccounts', // 5
    'analyticsAndReports', // 6
    'dedicatedSupportChannel', // 7
    'supplierMode', // 8
]

const PricingCard = ({
    plan,
    billingCycle,
    isRecommended = false,
    onSelect,
    disabled,
    isDowngrade,
}: PricingCardProps) => {
    const auth = useAuth()
    const navigate = useNavigate()
    const posthog = usePostHog()
    const dispatch = useDispatch()
    const [searchParams] = useSearchParams()

    const { team } = useSelector((state: any) => state.team)
    const { billingAccount } = useSelector((state: any) => state.billingAccount)

    const [isProcessing, setIsProcessing] = useState(false)
    const [price] = useState(plan.price[billingCycle ?? 'monthly'])
    const [displayFreeModal, setDisplayFreeModal] = useState<boolean>(false)
    const [selectedToLoading, setSelectedToLoading] = useState<string | null>(null)
    const [isConfirmModalOpen, setIsConfirModalOpen] = useState(false)

    const shouldTrialBeActive = () => {
        if (plan.id === 'free' || plan.id === 'free-plus') return false
        if (billingAccount?.plan?.id) return false
        return searchParams.get('trial') === 'false' ? false : true
    }

    const formatDocumentValue = (featName: string, value: number | string | boolean | undefined): string => {
        if (value === undefined) return 'No disponible'
        if (typeof value === 'boolean') return featureTranslations[featName]
        if (value === 'unlimited' && featureTranslations[featName] === 'integraciones')
            return `${featureTranslations[featName]} ilimitadas`
        if (value === 'unlimited') return `${featureTranslations[featName]} ilimitados`
        if (value === 1) {
            if (featName === 'seats') return `${value} asiento`
            if (featName === 'maxIntegrations') return `${value} integración`
        }
        if (featName === 'includedDocuments') {
            const includedDocs = plan?.price[`${billingCycle}`]?.includedDocuments || value
            return `${includedDocs} ${featureTranslations[featName]}`
        }
        if (featName === 'aditionalDocumentPrice') {
            if (typeof value === 'number') {
                return value === 0
                    ? 'Sin eventos adicionales'
                    : `Evento adicional: ${returnCurrencyValue(value / 100)} MXN`
            }
        }
        return `${value} ${featureTranslations[featName]}`
    }

    const formatFeatureValue = (featName: string): string => {
        return featureTranslations[featName]
    }

    const checkoutSubscription = async (id?: string) => {
        if (isProcessing) return

        try {
            setIsProcessing(true)
            setSelectedToLoading(id ?? plan.id)

            const formattedId = id?.replace('-', '_') ?? plan?.id?.replace('-', '_')
            trackEvent({
                name: `${formattedId}_plan_selected`,
                metadata: {
                    v: 'v2',
                    billingAccount: billingAccount?.id,
                    userId: auth.currentUser?.uid,
                }
            }, posthog)
            const currentUrl = new URL(window.location.href)
            const searchParams = new URLSearchParams(currentUrl.search)

            const isUpgrade =
                billingAccount?.plan?.id && billingAccount.plan.id !== plan.id && billingAccount.plan.id !== 'free'

            const successUrlPath = isUpgrade ? '/onboarding/finishSetup' : '/onboarding/plan?subscription=success'
            const cancelUrlPath = isUpgrade ? '/onboarding/finishSetup' : '/onboarding/plan?subscription=cancel'

            const cancelUrl = preserveQueryParams(`${getFrontUrl()}/${cancelUrlPath}`, searchParams)
            const successUrl = preserveQueryParams(`${getFrontUrl()}/${successUrlPath}`, searchParams)

            const billingManager = httpsCallable(getFunctions(), 'internalbillingapp')

            const billingData = {
                successUrl,
                cancelUrl,
                action: isUpgrade ? 'upgrade' : 'subscribe',
                plan: id ?? plan?.id,
                billingCycle: price.billingPeriod,
                trial: shouldTrialBeActive(),
                quantity: 1,
                isStaging: isStagingOrLocal(),
            }

            const checkout = await billingManager(billingData)

            if (!checkout.data) {
                throw new Error('No se recibió respuesta del servidor')
            }

            const data = checkout.data as any

            if (data?.onUpgradeCompleted) {
                navigate('/onboarding/finished')
                return
            }

            if (data.error) throw new Error(data.message)

            if (data?.onFree) {
                try {
                    const lastTeam = await getDoc(doc(getFirestore(), 'teams', team.id))
                    const lastBilling = await getDoc(doc(getFirestore(), 'billingAccounts', billingAccount.id))

                    if (lastTeam.exists()) {
                        dispatch(setTeam(lastTeam.data()))
                    }

                    if (lastBilling.exists()) {
                        dispatch(setBillingAccount(lastBilling.data()))
                    }

                    redirect(preserveQueryParams('/onboarding/team', searchParams))
                } catch (error) {
                    console.error('Error actualizando datos:', error)
                    message.error('Error actualizando la información del equipo')
                }
            } else {
                if (data.session?.url) {
                    // Agregar pequeño delay para asegurar que los estados se actualicen
                    setTimeout(() => {
                        window.open(data.session.url, '_self')
                    }, 100)
                } else if (data.raw?.message) {
                    message.error(data.raw.message)
                }
            }
        } catch (error) {
            console.error('Error detallado:', {
                error,
                state: {
                    selectedToLoading,
                    isConfirmModalOpen,
                    plan: plan.id,
                },
            })
            message.error('Hubo un error al procesar tu suscripción')
        } finally {
            setTimeout(() => {
                setSelectedToLoading(null)
                setIsProcessing(false)
            }, 1000) // Dar tiempo para que la redirección ocurra
        }
    }

    const sortedDocuments = documentsOrder.map((featName) => {
        if (featName === 'aditionalDocumentPrice') {
            return {
                name: featName,
                value: price?.additionalDocumentPrice!,
            }
        } else if (featName === 'includedDocuments') {
            return {
                name: featName,
                value: plan.price[`${billingCycle}`]?.includedDocuments || plan.features[featName],
            }
        } else {
            return {
                name: featName,
                value: plan.features[featName as keyof typeof plan.features],
            }
        }
    })

    const sortFeatures = (planId: string) => {
        let title: string
        let showFrom: number
        let showUntil: number
        switch (planId) {
            case 'advanced':
                title = 'Plan Esencial, más:'
                showFrom = 1
                showUntil = 2
                break
            case 'professional':
                title = 'Plan Avanzado, más:'
                showFrom = 3
                showUntil = 5
                break
            case 'professional-plus':
                title = 'Plan Profesional, más:'
                showFrom = 6
                showUntil = 7
                break

            default:
                title = 'Funcionalidades'
                showFrom = 0
                showUntil = 0
                break
        }

        return featureOrder
            .map((featName) => {
                return {
                    mapping: {
                        title,
                        showFrom,
                        showUntil,
                    },
                    name: featName,
                    value: plan.features[featName as keyof typeof plan.features],
                }
            })
            .filter((feature, index) => index >= feature.mapping.showFrom && index <= feature.mapping.showUntil)
    }

    const isCurrentPlan = billingAccount?.plan?.id === plan.id

    const getButtonText = () => {
        if (isCurrentPlan) return 'Plan actual'
        if (isDowngrade) return 'Comunicate con soporte'
        if (plan.id === 'free') return 'Comenzar gratis'
        if (plan.id === 'free-plus') return 'Continuar con tarjeta'
        if (billingAccount?.plan?.id) return 'Actualizar plan'
        if (!shouldTrialBeActive()) return 'Suscribirse'
        return 'Probar 12 días gratis'
    }

    const getButtonClass = () => {
        if (isCurrentPlan) return 'btn-outline'
        if (isRecommended) return 'btn-pricing-plans'
        if (disabled) return 'btn-secondary'
        if (plan.id === 'free') return 'btn-secondary'
        if (plan.id === 'free-plus') return 'btn-primary'
        return 'btn-secondary'
    }

    const handleButtonClick = async () => {
        if (!auth.currentUser?.uid) {
            const customPriceId = searchParams.get('customPriceId')
            const code = searchParams.get('code')
            let red = '/register?redirect=/memberships'

            if (customPriceId) {
                red = `${red}&customPriceId=${customPriceId}`
            }
            if (code) {
                red = `${red}&code=${code}`
            }
            return navigate(preserveQueryParams(red, searchParams))
        }

        if (selectedToLoading || isProcessing) {
            console.log('Ya hay una operación en proceso')
            return
        }

        try {
            if (plan.id === 'free' || plan.id === 'free-plus') {
                trackEvent({
                    name: 'start_with_free_plan',
                    metadata: {
                        v: 'v2',
                        billingAccount: billingAccount?.id,
                        userId: auth.currentUser?.uid,
                    }
                }, posthog)
                setDisplayFreeModal(true)
            } else if (billingAccount?.plan?.id) {
                setIsConfirModalOpen(true)
            } else {
                await checkoutSubscription()
            }
        } catch (error) {
            console.error('Error en el manejo del botón:', error)
            message.error('Ocurrió un error, por favor intenta de nuevo')
        }
    }

    return (
        <div className="d-flex flex-column">
            {isRecommended ? (
                <Row justify="end" style={{ marginBottom: 10 }}>
                    <Tag color="green">Elegido por el +63% de los usuarios</Tag>
                </Row>
            ) : (
                <Row justify="end" style={{ marginBottom: 10 }}>
                    <div style={{ height: 22 }}></div>
                </Row>
            )}
            <Card
                className="pricing-card shadow-lg rounded-lg"
                style={{ display: 'flex', flexDirection: 'column', padding: '25px 10px 10px 10px', minHeight: 680 }}
            >
                <FreePlansModal
                    selectedToLoading={selectedToLoading}
                    isOpen={displayFreeModal}
                    onClose={() => setDisplayFreeModal(false)}
                    ids={{
                        free: 'free',
                        freePlus: 'free-plus',
                    }}
                    selectedFreePlan={(id: string) => {
                        checkoutSubscription(id)
                    }}
                />
                <Space
                    direction="vertical"
                    size="small"
                    style={{ width: '100%', height: '100%', justifyContent: 'space-between' }}
                >
                    <Space direction="vertical" size="small" style={{ width: '100%' }}>
                        <Typography.Title level={3} style={{ margin: 0 }} onClick={() => console.log(plan)}>
                            {plan.name}
                        </Typography.Title>
                        <Typography.Text type="secondary">{plan?.description}</Typography.Text>
                        <Space direction="vertical" size={0}>
                            <Space align="center" style={{ marginBottom: 16 }}>
                                <Typography.Text>{price.currency} $</Typography.Text>
                                <Typography.Title level={2} style={{ margin: 0 }}>
                                    {billingCycle === 'monthly'
                                        ? returnCurrencyValue(Math.round(price.baseAmount!) / 100)
                                        : returnCurrencyValue(Math.round(price.baseAmount!) / 100 / 12)}
                                </Typography.Title>
                            </Space>
                            <Typography.Text type="secondary" style={{ marginTop: '0px' }}>
                                +IVA{' '}
                                {billingCycle === 'annual' && plan.id !== 'free'
                                    ? 'Facturado anualmente'
                                    : 'Facturado mensualmente '}
                            </Typography.Text>
                        </Space>

                        <Space direction="vertical" size="small" style={{ width: '100%' }}>
                            <Button
                                loading={selectedToLoading === plan.id}
                                className={getButtonClass()}
                                block
                                size="large"
                                onClick={handleButtonClick}
                                disabled={isCurrentPlan || disabled}
                            >
                                {getButtonText()}
                            </Button>
                        </Space>

                        <Space direction="vertical" style={{ width: '100%' }}>
                            <Typography.Title level={5}>Documentos</Typography.Title>

                            {sortedDocuments.map(({ name, value }) => (
                                <Space key={generateCode(8)} align="start">
                                    <Info size={20} weight="regular" style={{ color: 'var(--neutral-3)' }} />

                                    <Typography.Text className={'neutral-3'}>
                                        {formatDocumentValue(name, value)}
                                    </Typography.Text>
                                </Space>
                            ))}
                        </Space>
                        {sortFeatures(plan.id).map((feature, index) => {
                            console.log('feature', feature)
                            if (!feature || !feature.value) return <div key={generateCode(8)}></div>
                            return (
                                <Space direction="vertical" style={{ width: '100%' }} key={generateCode(8)}>
                                    {index === 0 && (
                                        <Typography.Title level={5}>{feature.mapping.title}</Typography.Title>
                                    )}

                                    <Space align="start">
                                        <Info size={20} weight="regular" style={{ color: 'var(--neutral-3)' }} />

                                        <Typography.Text className={'neutral-3'}>
                                            {formatFeatureValue(feature.name)}
                                        </Typography.Text>
                                    </Space>
                                </Space>
                            )
                        })}
                    </Space>
                </Space>
            </Card>

            <PlanConfirmationModal
                isOpen={isConfirmModalOpen}
                onClose={() => setIsConfirModalOpen(false)}
                onConfirm={() => checkoutSubscription(plan.id)}
                selectedPlan={plan}
                currentPlan={billingAccount?.plan}
                isLoading={Boolean(selectedToLoading)}
                billingCycle={billingCycle}
            />
        </div>
    )
}

export default PricingCard
