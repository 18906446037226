import { usePostHog } from 'posthog-js/react'
import { useState } from 'react'
import { trackEvent } from '../analytics/helpers'

interface LogoAndColors {
    logo_url: string | null
    colors: string[]
    clusters?: number
}

const useLogoAndColors = () => {
    const posthog = usePostHog()
    const [data, setData] = useState<LogoAndColors | null>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<Error | null>(null)

    const fetchLogoAndColors = async (domain: string) => {
        if (!domain) return

        try {
            setLoading(true)
            trackEvent({ name: 'onboarding_website_fetch_logo', metadata: { v: 'v2', domain } }, posthog)
            const response = await fetch('https://us-central1-gigstackpro.cloudfunctions.net/getLogoAndColors', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ domain }),
            })

            if (!response.ok) throw new Error('Network response was not ok')
            const data = await response.json()
            setData(data)
        } catch (err) {
            console.log('🚀 ~ fetchLogoAndColors ~ err:', err)
            setData({
                logo_url: null,
                colors: ['#044737', '#f4f7f2'],
                clusters: 5,
            })
            setError(err instanceof Error ? err : new Error('An error occurred'))
        } finally {
            setLoading(false)
        }
    }

    const removeLogo = () => {
        trackEvent({ name: 'onboarding_logo_deleted', metadata: { v: 'v2' } }, posthog)
        setData((prevData) => (prevData ? { ...prevData, logo_url: '' } : null))
    }

    return {
        logo: data?.logo_url,
        colors: data?.colors,
        loading,
        error,
        fetchLogoAndColors,
        removeLogo, // New function to remove logo
    }
}

export default useLogoAndColors
