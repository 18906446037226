import { Modal, Typography, Button, Space, Divider, Row } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from '../context/modalsSlice'
import { WarningCircle } from '@phosphor-icons/react'
import { colors } from '../designSystem/colors'
import { useSubscription } from '../customHooks/subscriptionHook'

const PaymentErrorModal = ({
    open,
    onClose,
    forceClosable,
}: {
    open: boolean
    onClose?: () => void
    forceClosable?: boolean
}) => {
    const dispatch = useDispatch()
    const { billingAccount } = useSelector((state: any) => state.billingAccount)
    const { currentSubscription } = useSubscription(billingAccount)

    const handleClose = () => {
        onClose?.()
        dispatch(closeModal('paymentErrorModalVisible'))
    }

    return (
        <Modal
            open={open}
            onCancel={handleClose}
            footer={null}
            width={360}
            centered
            closable={forceClosable !== undefined ? forceClosable : false}
            wrapStyle={{ padding: '32px 24px' }}
        >
            <Space direction="vertical" size={16} style={{ width: '100%' }}>
                <div style={{ textAlign: 'center' }}>
                    <Row justify="center" align="middle">
                        <WarningCircle
                            size={30}
                            weight="fill"
                            color={colors.red}
                            style={{ marginBottom: 16, marginRight: '10px' }}
                        />

                        <Typography.Text
                            style={{
                                fontSize: '18px',
                                fontWeight: 500,
                                display: 'block',
                                marginBottom: 8,
                                color: colors.headlines,
                            }}
                        >
                            Problemas con tu suscripción
                        </Typography.Text>
                    </Row>

                    <Typography.Text
                        style={{
                            fontSize: '14px',
                            display: 'block',
                            color: colors.text_muted,
                            lineHeight: '20px',
                        }}
                    >
                        Notamos que hubo un inconveniente con tu último pago. Para continuar disfrutando del servicio,
                        por favor actualiza tu método de pago.
                    </Typography.Text>
                </div>

                <Divider style={{ margin: '8px 0' }} />

                <Button
                    type="primary"
                    onClick={() => {
                        window.open(currentSubscription?.linkToPay, '_blank')
                    }}
                    style={{ width: '100%', height: 36 }}
                >
                    Actualizar método de pago
                </Button>
            </Space>
        </Modal>
    )
}

export default PaymentErrorModal
