import React from 'react'
import { Table, Space, Typography, Tag } from 'antd'
import moment from 'moment'
import { CopyableElementId } from '../UI/CopyableElementId'
import { VendorResourceModal } from './VendorResourceModal'
import { InvoiceStatusTag, PaymentStatusTag, ReceiptStatusTag } from '../UI/ElementStatus'

export interface Resource {
    id: string
    uuid: string
    folio: string
    series: string
    currency: string
    timestamp: string
    use: string
    rate: number
    payment_form: string
    payment_method: string
    total: number
    amount: number
    from: string
    status: string
    client: {
        id: string
        name: string
        legalName: string
        email: string
        country: string
        zip: string
    }
    team: {
        id: string
        name: string
        rfc: string
        legalName: string
    }
    resourceType: 'payment' | 'receipt' | 'invoice'
}

export const setResourceBadge = (resourceType: string) => {
    switch (resourceType) {
        case 'payment':
            return 'Pago'
        case 'receipt':
            return 'Recibo'
        case 'invoice':
            return 'Factura'
    }
}

export const setStatusBadge = (resource: Resource) => {
    switch (resource.resourceType) {
        case 'payment':
            return <PaymentStatusTag shape="circle" record={resource} />
        case 'receipt':
            return <ReceiptStatusTag shape="circle" record={resource} />
        case 'invoice':
            return <InvoiceStatusTag shape="circle" record={resource} />
    }
}

interface VendorResourceTableProps {
    documents: Resource[]
    loading: boolean
    current: number
    pageSize: number
    onPageChange: (page: number, pageSize: number) => void
}

export const VendorsResourcesTable: React.FC<VendorResourceTableProps> = ({
    documents,
    loading,
    current,
    pageSize,
    onPageChange,
}) => {
    const [selectedResource, setSelectedResource] = React.useState<Resource | null>(null)
    const [isModalOpen, setIsModalOpen] = React.useState(false)

    const handleResourceSelect = (resource: Resource) => {
        setSelectedResource(resource)
        setIsModalOpen(true)
    }

    const handleCloseModal = () => {
        setIsModalOpen(false)
        setSelectedResource(null)
    }

    const columns = [
        {
            title: 'Cuenta de emisión',
            dataIndex: 'team',
            key: 'team',
            render: (team: { name: string; legalName: string }) => (
                <div>
                    <Typography>{team.name}</Typography>
                    <Typography className="p-small-medium">{team.legalName}</Typography>
                </div>
            ),
        },
        {
            title: 'Documento',
            dataIndex: 'id',
            key: 'id',
            render: (text: string, record: Resource) => {
                const displayId = record.uuid ? record.uuid : text

                return (
                    <div>
                        <Tag
                            className="bg-white rounded-full"
                            onClick={() => handleResourceSelect(record)}
                            style={{ cursor: 'pointer' }}
                        >
                            <Typography.Text className="p-small-medium">
                                {setResourceBadge(record.resourceType)}{' '}
                            </Typography.Text>
                        </Tag>
                        {displayId && <CopyableElementId id={displayId} idName="id" />}
                    </div>
                )
            },
        },
        {
            title: 'Fecha',
            dataIndex: 'timestamp',
            key: 'timestamp',
            render: (timestamp: string) => moment(timestamp).format('DD MMM YYYY HH:mm'),
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            render: (total: number, record: Resource) => {
                const totalDisplay = total ? total : record.amount / 100

                return (
                    <span>
                        {totalDisplay.toLocaleString('en-US', { style: 'currency', currency: record.currency })}
                    </span>
                )
            },
        },
        {
            title: 'Estado',
            dataIndex: 'status',
            key: 'status',
            render: (text: string, record: Resource) => setStatusBadge(record),
        },
    ]

    return (
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            <Space></Space>
            <Table
                columns={columns}
                dataSource={documents}
                loading={loading}
                rowKey="id"
                pagination={{
                    current: current,
                    pageSize: pageSize,
                    onChange: onPageChange,
                    showSizeChanger: true,
                    showQuickJumper: true
                }}
            />
            <VendorResourceModal isOpen={isModalOpen} onClose={handleCloseModal} resource={selectedResource} />
        </Space>
    )
}
