import { Badge, Button, Col, DatePicker, Dropdown, Form, Row, Space, Typography } from 'antd'
import PaymentsTable from '../components/Tables/PaymentsTable'
import DynamicTabs from '../components/Menu/Tabs'
import { CalendarBlank, CurrencyDollar, DownloadSimple, Gear, PlusCircle, SelectionSlash } from '@phosphor-icons/react'
import RecurringPaymentsTable from '../components/Tables/RecurringPayments'
import TablesContainer from '../components/Tables/TablesContainer'
import { openModal } from '../context/modalsSlice'
import { useDispatch, useSelector } from 'react-redux'
import PaymentsActionsInsights from '../components/ComponentIndividual/PaymentsActionsInsights'
import { setData } from '../context/dataSlice'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import moment from 'moment'
import { SearchParamInURL } from '../functions/helpers'
import { collection, getCountFromServer, getFirestore, orderBy, query, where } from 'firebase/firestore'
import { usePermissions } from '../customHooks/usePermissions'
import { usePostHog } from 'posthog-js/react'
import PaymentLinksTable from '../components/Tables/PaymentLinksTable'
import { trackEvent } from '../analytics/helpers'

const PaymentsPage: React.FC = () => {
    const posthog = usePostHog()
    const { canCreatePayment } = usePermissions()
    const { team } = useSelector((state: any) => state.team)
    const dispatch = useDispatch()
    const subtab = SearchParamInURL('subtab')
    const [defaultActiveKey, setDefaultActiveKey] = useState(subtab ?? '1')
    const [periods, setPeriods] = useState<any>({
        start: null,
        end: null,
    })
    const [pendingPaymentsReview, setPendingPaymentsReview] = useState({
        count: 0,
        loaded: false,
    })

    const { selectedPayments } = useSelector((state: any) => state.data)

    useEffect(() => {
        return () => {
            const q = query(
                collection(getFirestore(), 'payments'),
                where('team', '==', team?.id),
                where('status', '==', 'review_requested'),
                orderBy('review_created_at', 'desc'),
            )
            if (!pendingPaymentsReview.loaded) {
                getCountFromServer(q)
                    .then((res) => {
                        setPendingPaymentsReview({
                            count: res.data().count,
                            loaded: true,
                        })
                    })
                    .catch((err) => {
                        setPendingPaymentsReview({ count: 0, loaded: true })
                    })
            }
        }
    }, [pendingPaymentsReview?.loaded, team?.id])

    const openDrawer = async () => { 
        trackEvent({ name: 'new_payment' }, posthog)
        dispatch(openModal('paymentModalVisible'))
    }

    const tabsConfig = [
        {
            key: 'payments',
            title: 'Pagos',
            content: (
                <TablesContainer
                    children={<PaymentsTable max={10} startPeriod={periods.start} endPeriod={periods.end} />}
                    title={'Pagos'}
                    description={'Registros y solicitudes de pagos de todas las fuentes conectadas a gigstack.'}
                    actions={
                        <>
                            <Col>
                                <Space wrap>
                                    <Form
                                        initialValues={{
                                            dates: periods.start ? [dayjs(periods.start), dayjs(periods.end)] : [],
                                        }}
                                    >
                                        <Form.Item
                                            name="dates"
                                            style={{
                                                margin: 0,
                                                padding: 0,
                                                marginLeft: '5px',
                                            }}
                                        >
                                            <DatePicker.RangePicker
                                                style={{ maxWidth: '250px' }}
                                                suffixIcon={
                                                    <CalendarBlank size={17} weight="regular" className="icon" />
                                                }
                                                format="DD MMM YYYY"
                                                onChange={(a: any) => {
                                                    if (!a)
                                                        return setPeriods({
                                                            start: null,
                                                            end: null,
                                                        })
                                                    else
                                                        setPeriods({
                                                            start: moment(a[0].valueOf()).startOf('day').valueOf(),
                                                            end: moment(a[1].valueOf()).endOf('day').valueOf(),
                                                        })
                                                }}
                                                presets={[
                                                    {
                                                        label: `${moment().startOf('month').format('D')} al ${moment()
                                                            .endOf('month')
                                                            .format('D MMMM YYYY')}`,
                                                        value: [dayjs().startOf('month'), dayjs().endOf('month')],
                                                    },
                                                    {
                                                        label: `${moment()
                                                            .add(-1, 'month')
                                                            .startOf('month')
                                                            .format('D')} al ${moment()
                                                            .add(-1, 'month')
                                                            .endOf('month')
                                                            .format('D MMMM YYYY')}`,
                                                        value: [
                                                            dayjs().add(-1, 'month').startOf('month'),
                                                            dayjs().add(-1, 'month').endOf('month'),
                                                        ],
                                                    },
                                                    {
                                                        label: '60 días',
                                                        value: [
                                                            dayjs().add(-60, 'days').startOf('day'),
                                                            dayjs().endOf('day'),
                                                        ],
                                                    },
                                                ]}
                                            />
                                        </Form.Item>
                                    </Form>
                                    <Button
                                        type="dashed"
                                        disabled={!canCreatePayment}
                                        icon={<Gear size={18} className="iconColor" weight="regular" />}
                                    ></Button>
                                </Space>
                            </Col>
                            <Col>
                            <Space>
                                {(selectedPayments.length ?? 0) > 0 && (
                                <Dropdown
                                    menu={{
                                        items: [
                                            {
                                                onClick: () => {
                                                    dispatch(setData({ item: 'selectedPayments', data: [] }))
                                                },
                                                label: (
                                                    <Typography.Text className="smallparagaph ">
                                                        <SelectionSlash
                                                            weight="regular"
                                                            size={15}
                                                            style={{ marginRight: '5px' }}
                                                        />{' '}
                                                        Deshacer selección
                                                    </Typography.Text>
                                                ),
                                                key: 'unselect',
                                            },
                                            {
                                                onClick: () => {
                                                    dispatch(openModal('confirmMassivePayInvoiceModalVisible'))
                                                },
                                                label: (
                                                    <Typography.Text className="smallparagaph ">
                                                        <CurrencyDollar
                                                            weight="regular"
                                                            size={15}
                                                            style={{ marginRight: '5px' }}
                                                        />
                                                        Crear facturas
                                                    </Typography.Text>
                                                ),
                                                key: 'createInvoice',
                                            },
                                        ],
                                    }}
                                    trigger={['click']}
                                >
                                    <Button style={{ marginLeft: '10px' }}>Opciones masivas</Button>
                                </Dropdown>
                            )}    
                                <Space wrap>
                                    <Button
                                        className="btn-secondary"
                                        disabled={!canCreatePayment}
                                        icon={<DownloadSimple className="iconColor" size={18} weight="regular" />}
                                        onClick={() => {
                                            dispatch(openModal('downloadsModalVisible'))
                                            dispatch(
                                                setData({
                                                    item: 'downloadInfo',
                                                    data: {
                                                        type: 'payments',
                                                        readableType: 'pagos',
                                                    },
                                                }),
                                            )
                                        }}
                                    />
                                    <Button
                                        className="btn-secondary"
                                        disabled={!canCreatePayment}
                                        onClick={() => dispatch(openModal('registerPaymentModalVisible'))}
                                    >
                                        Registrar Pago
                                    </Button>
                                    <Button className="btn-primary" disabled={!canCreatePayment} onClick={openDrawer}>
                                        Nuevo Pago
                                    </Button>
                                </Space>
                            </Space>    
                            </Col>
                        </>
                    }
                />
            ),
        },
        {
            key: 'recurrent',
            title: 'Pagos recurrentes',
            content: (
                <TablesContainer
                    children={<RecurringPaymentsTable max={1000} />}
                    title={'Pagos Recurrentes'}
                    description={
                        'Solicita pagos a tus clientes de manera recurrente, se enviará de forma automática de acuerdo a las reglas que pongas.'
                    }
                    actions={
                        <div style={{ width: '100%' }}>
                            <Row justify="end" style={{ marginBottom: '16px' }}>
                                <Button
                                    size="middle"
                                    type="primary"
                                    onClick={() => {
                                        dispatch(
                                            setData({
                                                item: 'payment',
                                                data: {
                                                    from: 'recurringEvent',
                                                    action: 'create',
                                                },
                                            }),
                                        )
                                        dispatch(
                                            setData({
                                                item: 'recurringEventData',
                                                data: {},
                                            }),
                                        )
                                        dispatch(setData({ item: 'client', data: null }))
                                        dispatch(
                                            setData({
                                                item: 'selectedServices',
                                                data: [],
                                            }),
                                        )
                                        dispatch(openModal('paymentModalVisible'))
                                    }}
                                    icon={<PlusCircle className="" size={18} weight="regular" />}
                                    disabled={!canCreatePayment}
                                >
                                    Nueva recurrencia
                                </Button>
                            </Row>
                        </div>
                    }
                />
            ),
        },
        {
            key: 'paymentLinks',
            title: 'Enlaces de pago',
            content: (
                <TablesContainer
                    children={<PaymentLinksTable max={1000} />}
                    title={'Enlaces de pago'}
                    description={'Crea un enlace de pago único y envíalo las veces que necesites.'}
                    actions={
                        <div style={{ width: '100%' }}>
                            <Row justify="end" style={{ marginBottom: '16px' }}>
                                
                                <Button
                                    size="middle"
                                    type="primary"
                                    onClick={() => {
                                        dispatch(
                                            setData({
                                                item: 'payment',
                                                data: {
                                                    from: 'paymentLink',
                                                },
                                            }),
                                        )
                                        dispatch(setData({ item: 'client', data: null }))
                                        dispatch(
                                            setData({
                                                item: 'selectedServices',
                                                data: [],
                                            }),
                                        )
                                        dispatch(openModal('paymentModalVisible'))
                                    }}
                                    icon={<PlusCircle className="" size={18} weight="regular" />}
                                    disabled={!canCreatePayment}
                                >
                                    Nuevo enlace de pago
                                </Button>
                            </Row>
                        </div>
                    }
                />
            ),
        },
        {
            key: 'actions',
            title: (
                <Badge showZero={false} count={pendingPaymentsReview?.count}>
                    <div
                        role="tab"
                        aria-selected="false"
                        className="ant-tabs-tab-btn"
                        tabIndex={0}
                        id="rc-tabs-1-tab-recurrent"
                        aria-controls="rc-tabs-1-panel-recurrent"
                    >
                        {' '}
                        Acciones / Insights
                    </div>
                </Badge>
            ),
            content: <PaymentsActionsInsights totalPendingCount={pendingPaymentsReview.count} />,
        },
    ]
    const handleTabChange = (activeKey: string) => {
        setDefaultActiveKey(activeKey)
    }

    return (
        <>
            <Typography.Text className="h2-bold neutral-1" style={{ marginBottom: '15px' }}>
                Pagos
            </Typography.Text>
            <DynamicTabs
                draggable={false}
                defaultActiveKey={defaultActiveKey}
                tabsConfig={tabsConfig}
                onChange={handleTabChange}
            />
        </>
    )
}
export default PaymentsPage
