import { PostHog } from 'posthog-js'
import TagManager from 'react-gtm-module'

interface AnalyticsEvent {
    name: string
    metadata?: Record<string, any>
}

/**
 * @param event Event name
 * @param posthog PostHog instance (optional)
 */
export const trackEvent = ({ name, metadata = {} }: AnalyticsEvent, posthog?: PostHog) => {
    try {
        if (posthog) {
            posthog.capture(name, metadata)
        }

        TagManager.dataLayer({
            dataLayer: {
                event: name,
                category: 'User Action',
                ...metadata,
                timestamp: new Date().toISOString()
            }
        })
        
    } catch (error) {
        console.error('Error tracking event:', error)
    }
}

export const initializeGTM = (gtmId: string) => {
    try {
        TagManager.initialize({
            gtmId,
            dataLayerName: 'dataLayer',
            events: {
                pageView: 'pageview',
                click: 'click',
                formSubmit: 'form_submit'
            }
        })
    } catch (error) {
        console.error('Error initializing GTM:', error)
    }
}




