import { collection, doc, getDoc, getDocs, getFirestore, query, where } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSigninCheck } from 'reactfire'
import { TeamDef } from '../../interfaces/teamDef'
import Loader from '../appStates/LoadingView'
import { setBillingAccount } from '../context/billingAccountSlice'
import { setNeedsOnboarding } from '../context/onboardingSlice'
import { setTeam, setTeams } from '../context/teamsSlice'
import { setUser } from '../context/userSlice'
import { openTallyForm } from './AuthScreensWrapper'
import moment from 'moment'
import { usePostHog } from 'posthog-js/react'

interface AuthWrapperProps {
    children: JSX.Element
    publicRoutes: JSX.Element
    onboardingRoutes: JSX.Element
}

interface LoadingState {
    isLoading: boolean
    error: string | null
    dataLoaded: boolean
}

const AuthWrapper = ({ children, publicRoutes, onboardingRoutes }: AuthWrapperProps) => {
    const db = getFirestore()
    const dispatch = useDispatch()
    const posthog = usePostHog()
    const { data: signInCheckResult, status } = useSigninCheck()

    const [loadingState, setLoadingState] = useState<LoadingState>({
        isLoading: true,
        error: null,
        dataLoaded: false,
    })
    const { needsOnboarding } = useSelector((state: any) => state.onboarding)

    useEffect(() => {
        const loadInitialData = async () => {
            setLoadingState({ isLoading: true, error: null, dataLoaded: false })
            try {
                if (!signInCheckResult?.signedIn || !signInCheckResult?.user) {
                    setLoadingState({ isLoading: false, error: null, dataLoaded: true })
                    return
                }

                const userId = signInCheckResult.user.uid
                let userData
                try {
                    const userDoc = await getDoc(doc(db, 'users', userId))
                    userData = userDoc.data()
                    dispatch(setUser(userData))
                } catch (error) {
                    console.error('Error loading user:', error)
                    throw error
                }

                const name = userData?.name || `${userData?.firstName} ${userData?.lastName}`
                const email = userData?.email || signInCheckResult?.user?.email || ''
                try {
                    posthog?.identify(userData?.uid, {
                        email,
                        firstName: userData?.firstName ?? userData?.name,
                        lastName: userData?.lastName ?? '',
                        country: userData?.address?.country ?? '',
                        phone: userData?.phone ?? '',
                        name: name,
                    })
                } catch (error) { 
                    posthog.register({
                        name: name,
                        email: email,
                        phone: userData?.phone
                    })
                }

                let billingAccountData, teamData

                if (userData?.lastBillingAccountViewed) {
                    try {
                        const billingAccount = await getDoc(
                            doc(db, 'billingAccounts', userData.lastBillingAccountViewed),
                        )
                        billingAccountData = billingAccount.data()
                        dispatch(setBillingAccount(billingAccountData))
                    } catch (error) {
                        console.error('Error loading billing account:', error)
                        throw error
                    }
                }

                if (userData?.lastTeamViewed) {
                    try {
                        const [teamDoc, teamsQuery] = await Promise.all([
                            getDoc(doc(db, 'teams', userData.lastTeamViewed)),
                            getDocs(query(collection(db, 'teams'), where('membersIds', 'array-contains', userId))),
                        ])

                        teamData = teamDoc.data()

                        dispatch(setTeam(teamData ?? []))
                        dispatch(setTeams(teamsQuery.docs.map((doc) => doc.data() as TeamDef)))
                    } catch (error) {
                        console.error('Error loading team:', error)
                        throw error
                    }
                }

                const isLegacyPlan = billingAccountData?.plan?.pricingVersion === 'v1'
                const onboardingNeeded =
                    isLegacyPlan || teamData?.defaults?.skipOnboarding
                        ? false
                        : !userData ||
                          !userData.email ||
                          !userData.name ||
                          !signInCheckResult.user.displayName ||
                          !userData.lastTeamViewed ||
                          !teamData ||
                          (billingAccountData?.plan?.pricingVersion === 'v2' && !billingAccountData.plan?.id) ||
                          !teamData.brand.alias ||
                          !teamData.brand.primaryColor ||
                          (!teamData.legal_name && !teamData.legalName) ||
                          !teamData.address?.zip ||
                          !teamData.rfc ||
                          !teamData.facturapi?.completed

                dispatch(setNeedsOnboarding(onboardingNeeded))
                setLoadingState({ isLoading: false, error: null, dataLoaded: true })

                // Check if the user has been registered for more than 3 months
                if (moment(userData?.timestamp).isBefore(moment().add(-3, 'months'))) {
                    // Get NPS data from user or set default values if none exists
                    const nps = userData?.nps || {
                        lastShown: 0, // Timestamp when survey was last shown
                        event: 'closed', // Last action: 'closed' or 'submitted'
                    }

                    // Convert timestamps to moment objects for comparison
                    const momentLastShown = moment(nps.lastShown)
                    const closedMoment = moment().add(-1, 'month') // 1 month ago
                    const submittedMoment = moment().add(-3, 'months') // 3 months ago

                    // Show survey if:
                    // 1. User previously submitted the survey AND it's been more than 3 months since last shown
                    if (momentLastShown.isBefore(submittedMoment) && nps.event === 'submitted') {
                        openTallyForm(userData)
                    }
                    // 2. User previously closed the survey AND it's been more than 1 month since last shown
                    else if (momentLastShown.isBefore(closedMoment) && nps.event === 'closed') {
                        openTallyForm(userData)
                    }
                }
            } catch (error) {
                console.error('Error loading initial data:', error)
                setLoadingState({
                    isLoading: false,
                    error: error instanceof Error ? error.message : 'Failed to load data',
                    dataLoaded: false,
                })
            }
        }

        if (status === 'success') {
            loadInitialData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, signInCheckResult, dispatch, db])

    if (status === 'loading' || loadingState.isLoading) {
        return <Loader loading={true} />
    }

    if (!signInCheckResult?.signedIn) {
        return loadingState.dataLoaded ? publicRoutes : <Loader loading={true} />
    }

    if (needsOnboarding) {
        return loadingState.dataLoaded ? onboardingRoutes : <Loader loading={true} />
    }

    return loadingState.dataLoaded ? children : <Loader loading={true} />
}

export default AuthWrapper
