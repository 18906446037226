import { Button, Divider, Form, Typography } from 'antd'
import { useLocation } from 'react-router-dom'
import { PasswordInput, TextInput } from '../../components/Forms/Inputs'
import { useCustomAuth } from '../../customHooks/useCustomAuth'
import { preserveQueryParams } from '../../functions/helpers'
import AuthFormLayout from './AuthFormLayout'
import GoogleSignInButton from './GoogleSignInButton'
import { usePostHog } from 'posthog-js/react'
import { useState } from 'react'
import ForgotPasswordModal from '../../modals/ForgotPasswordModal'
import { trackEvent } from '../../analytics/helpers'

const { Text } = Typography

const LoginForm = () => {
    const posthog = usePostHog()
    const location = useLocation()
    const { loading, login, googleSignIn } = useCustomAuth()
    const [showModal, setShowModal] = useState(false)

    const getSignupLink = () => {
        const currentSearchParams = new URLSearchParams(location.search)
        const signup = preserveQueryParams('/register', currentSearchParams)
        return signup
    }

    const onFinish = (values: any) => {
        trackEvent({ name: 'signed_in_with_email', metadata: { email: values.email, v: 'v2' } }, posthog)
        login(values.email, values.password)
    }

    return (
        <AuthFormLayout
            title="Inicia sesión"
            footerText="¿Aún no tienes cuenta?"
            footerLinkText="Regístrate"
            footerLinkTo={getSignupLink()}
        >   
            {showModal && <ForgotPasswordModal open={showModal} close={() => setShowModal(false)} />}
            <Form
                name="login"
                initialValues={{ remember: true }}
                layout="vertical"
                onFinish={onFinish}
                style={{ marginTop: '24px' }}
            >
                <Form.Item
                    name="email"
                    rules={[
                        { required: true, message: 'Por favor ingresa tu correo electrónico' },
                        { type: 'email', message: 'Por favor ingresa un correo electrónico válido' },
                    ]}
                >
                    <TextInput
                        name="email"
                        label="Correo electrónico"
                        placeholder="Escribe tu correo electrónico empresarial"
                    />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[
                        { required: true, message: 'Por favor ingresa tu contraseña' },
                        { min: 8, message: 'La contraseña debe tener al menos 8 caracteres' },
                    ]}
                >
                    <PasswordInput label="Contraseña" name="password" placeholder="Escribe tu contraseña" />
                </Form.Item>
                <Typography className='p-base-regular primary-1 clickable' style={{ textAlign: 'end', marginBottom: '7px' }} onClick={() => setShowModal(true)}>¿Olvidaste tu contraseña?</Typography>
                <Form.Item>
                    <Button
                        className="btn-primary"
                        disabled={loading.length !== 0}
                        htmlType="submit"
                        block
                        loading={loading.includes('email')}
                    >
                        Iniciar Sesión
                    </Button>
                </Form.Item>
            </Form>
            <Divider plain className="neutral-4">
                <Text className="p-xsmall-regular neutral-3">o inicia con</Text>
            </Divider>
            <GoogleSignInButton onClick={() => {
                trackEvent({ name: 'signed_in_with_google', metadata: { v: 'v2' } }, posthog)
                googleSignIn()
            }} loading={loading.includes('google')} />
        </AuthFormLayout>
    )
}

export default LoginForm
