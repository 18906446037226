import { Button, Space, Typography, DatePicker, Form, Col, Row, Dropdown } from 'antd'
import InvoicesTable from '../components/Tables/InvoicesTable'
import DynamicTabs from '../components/Menu/Tabs'
import { CalendarBlank, DownloadSimple, Calendar } from '@phosphor-icons/react'
import TablesContainer from '../components/Tables/TablesContainer'
import { useDispatch } from 'react-redux'
import { openModal, setElementSelected } from '../context/modalsSlice'
import InvoicesActionsInsights from '../components/ComponentIndividual/InvoicesActionsInsights'
import RecurringInvoicesTable from '../components/Tables/RecurringInvoices'
import { setData } from '../context/dataSlice'
import moment from 'moment'
import { useState } from 'react'
import dayjs from 'dayjs'
import { usePermissions } from '../customHooks/usePermissions'
import { usePostHog } from 'posthog-js/react'

import ReusableInvoiceTable from '../components/Tables/ReusableInvoiceTable'
import { useNavigate } from 'react-router-dom'
import { SearchParamInURL } from '../functions/helpers'
import { MassInvoicesTable } from '../components/Tables/MassInvoicesTable'
import { MenuProps } from 'antd/lib'
import { addParamToUrl } from '../functions/UrlParams'
import { trackEvent } from '../analytics/helpers'

const InvoicePage: React.FC = () => {
    const posthog = usePostHog()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { canCreateInvoice } = usePermissions()

    const activeKey = SearchParamInURL('subtab') ?? undefined
    const [periods, setPeriods] = useState<any>({
        start: null,
        end: null,
    })

    const createInvoiceMenuItems: MenuProps['items'] = [
        {
            key: 'createInvoice',
            label: <Typography>Crear factura</Typography>,
            onClick: async () => {
                trackEvent({ name: 'new_invoice' }, posthog)
                dispatch(openModal('invoiceModalVisible'))
                dispatch(setData({ item: 'invoice', data: { from: 'manual', id: null } }))
                try {
                    window.Intercom('trackEvent', 'new_invoice_initiated')
                } catch (error) {
                    console.log('error', error)
                }
            },
        },
        {
            key: 'massiveInvoice',
            label: <Typography>Facturación masiva</Typography>,
            onClick: () => {
                trackEvent({ name: 'massive_invoice' }, posthog)
                addParamToUrl('subtab', 'massiveActions')
                dispatch(openModal('createMassiveInvoicesModalVisible'))
            }
        },
        // {
        //     key: 'massiveCancelation',
        //     label: <Typography>Cancelación masiva</Typography>,
        //     onClick: () => {
        //         try {
        //             posthog.capture('massive_cancelation')
        //         } catch (error) {}
        //         // TODO: Implement cancelation modal
        //     }
        // },
    ]

    const tabsConfig = [
        {
            key: 'overview',
            title: 'Facturas',
            content: (
                <TablesContainer
                    title="Lista de facturas emitidas"
                    description="Consulta la lista de facturas emitidas desde el portal de gigstack, automatizaciones y recurrencias."
                    actions={
                        <>
                            <Col>
                                <Form
                                    initialValues={{
                                        dates: periods.start ? [dayjs(periods.start), dayjs(periods.end)] : [],
                                    }}
                                >
                                    <Form.Item name="dates" style={{ margin: 0, padding: 0, marginLeft: '5px' }}>
                                        <DatePicker.RangePicker
                                            style={{ maxWidth: '250px' }}
                                            suffixIcon={<Calendar size={17} weight="regular" className="icon" />}
                                            format="DD MMM YYYY"
                                            onChange={(a: any) => {
                                                if (!a)
                                                    return setPeriods({
                                                        start: null,
                                                        end: null,
                                                    })
                                                else
                                                    setPeriods({
                                                        start: moment(a[0].valueOf()).startOf('day').valueOf(),
                                                        end: moment(a[1].valueOf()).endOf('day').valueOf(),
                                                    })
                                            }}
                                            presets={[
                                                {
                                                    label: `${moment().startOf('month').format('D')} al ${moment().endOf('month').format('D MMMM YYYY')}`,
                                                    value: [dayjs().startOf('month'), dayjs().endOf('month')],
                                                },
                                                {
                                                    label: `${moment().add(-1, 'month').startOf('month').format('D')} al ${moment().add(-1, 'month').endOf('month').format('D MMMM YYYY')}`,
                                                    value: [
                                                        dayjs().add(-1, 'month').startOf('month'),
                                                        dayjs().add(-1, 'month').endOf('month'),
                                                    ],
                                                },
                                                {
                                                    label: '60 días',
                                                    value: [
                                                        dayjs().add(-60, 'days').startOf('day'),
                                                        dayjs().endOf('day'),
                                                    ],
                                                },
                                            ]}
                                        />
                                    </Form.Item>
                                </Form>
                            </Col>
                            <Col>
                                <Space wrap>
                                    <Button
                                        disabled={!canCreateInvoice}
                                        className="btn-secondary"
                                        icon={<DownloadSimple className="iconColor" size={18} weight="regular" />}
                                        onClick={() => {
                                            dispatch(openModal('downloadsModalVisible'))
                                            dispatch(
                                                setData({
                                                    item: 'downloadInfo',
                                                    data: { type: 'invoices', readableType: 'facturas' },
                                                }),
                                            )
                                        }}
                                    >
                                        Descargar
                                    </Button>
                                    {/* <Button
                                        disabled={!canCreateInvoice}
                                        className="btn-secondary"
                                        icon={<Table size={18} className="iconColor" weight="regular" />}
                                    >
                                        Tabla
                                    </Button> */}
                                    <Dropdown
                                        menu={{ items: createInvoiceMenuItems }}
                                        disabled={!canCreateInvoice}
                                    >
                                        <Button
                                            disabled={!canCreateInvoice}
                                            className="btn-primary"
                                        >
                                            Crear
                                        </Button>
                                    </Dropdown>
                                </Space>
                            </Col>
                        </>
                    }
                    children={
                        //TODO: HAY QUE BUSCAR POR I, E, IGNORAR P
                        <InvoicesTable startPeriod={periods.start} endPeriod={periods.end} max={10} />
                    }
                />
            ),
        },
        {
            key: 'complements',
            title: 'Complementos de pago',
            content: (
                <TablesContainer
                    //TODO: HACER QUE URL PARAMS NO AFECTE A TODO O AFECTE DIFERENTE
                    //TODO: EL QUERY DE FECHAS NO ESTÁ FUNCIONANDO CON PAYMENTCOMPLEMENTSTABLE

                    children={
                        <ReusableInvoiceTable
                            invoiceTypes={['P']}
                            startPeriod={periods.start}
                            endPeriod={periods.end}
                            max={10}
                        />
                    }
                    title={'Complementos de pago'}
                    description={'Listado de los complementos de pago emitidos.'}
                    actions={
                        <>
                            <Col>
                                <Form
                                    initialValues={{
                                        dates: periods.start ? [dayjs(periods.start), dayjs(periods.end)] : [],
                                    }}
                                >
                                    <Form.Item name="dates" style={{ margin: 0, padding: 0, marginLeft: '5px' }}>
                                        <DatePicker.RangePicker
                                            style={{ maxWidth: '250px' }}
                                            suffixIcon={<CalendarBlank size={17} weight="regular" className="icon" />}
                                            format="DD MMM YYYY"
                                            onChange={(a: any) => {
                                                if (!a)
                                                    return setPeriods({
                                                        start: null,
                                                        end: null,
                                                    })
                                                else
                                                    setPeriods({
                                                        start: moment(a[0].valueOf()).startOf('day').valueOf(),
                                                        end: moment(a[1].valueOf()).endOf('day').valueOf(),
                                                    })
                                            }}
                                            presets={[
                                                {
                                                    label: `${moment().startOf('month').format('D')} al ${moment().endOf('month').format('D MMMM YYYY')}`,
                                                    value: [dayjs().startOf('month'), dayjs().endOf('month')],
                                                },
                                                {
                                                    label: `${moment().add(-1, 'month').startOf('month').format('D')} al ${moment().add(-1, 'month').endOf('month').format('D MMMM YYYY')}`,
                                                    value: [
                                                        dayjs().add(-1, 'month').startOf('month'),
                                                        dayjs().add(-1, 'month').endOf('month'),
                                                    ],
                                                },
                                                {
                                                    label: '60 días',
                                                    value: [
                                                        dayjs().add(-60, 'days').startOf('day'),
                                                        dayjs().endOf('day'),
                                                    ],
                                                },
                                            ]}
                                        />
                                    </Form.Item>
                                </Form>
                            </Col>
                            <Col>
                                <Space wrap>
                                    <Button
                                        className="btn-primary"
                                        disabled={!canCreateInvoice}
                                        onClick={async () => {
                                            trackEvent({ name: 'new_invoice' }, posthog)
                                            dispatch(openModal('invoiceModalVisible'))
                                            dispatch(setData({ item: 'invoice', data: { from: 'manual', id: null } }))
                                            try {
                                                window.Intercom('trackEvent', 'new_invoice_initiated')
                                            } catch (error) {
                                                console.log('error', error)
                                            }
                                        }}
                                    >
                                        Crear
                                    </Button>
                                </Space>
                            </Col>
                        </>
                    }
                />
            ),
        },
        {
            key: 'recurringInvoices',
            title: 'Facturas recurrentes',
            content: (
                <TablesContainer
                    children={<RecurringInvoicesTable max={10} />}
                    title={'Facturas Recurrentes'}
                    description={
                        'Solicita facturas a tus clientes de manera recurrente, se enviará de forma automática de acuerdo a las reglas que pongas.'
                    }
                    actions={
                        <>
                            <Col></Col>
                            <Col>
                                <Button
                                    className="btn-primary"
                                    disabled={!canCreateInvoice}
                                    onClick={() => {
                                        dispatch(setData({ item: 'recurringEventData', data: {} }))
                                        dispatch(setData({ item: 'client', data: null }))
                                        dispatch(setData({ item: 'selectedServices', data: [] }))
                                        dispatch(
                                            setData({
                                                item: 'invoice',
                                                data: {
                                                    from: 'recurringEvent',
                                                    id: null,
                                                },
                                            }),
                                        )
                                        dispatch(
                                            setElementSelected({
                                                element: 'recurringInvoice',
                                                data: true,
                                            }),
                                        )
                                        dispatch(openModal('invoiceModalVisible'))
                                    }}
                                >
                                    Crear
                                </Button>
                                <Space wrap></Space>
                            </Col>
                        </>
                    }
                />
            ),
        },
        {
            key: 'massiveActions',
            title: 'Facturas masivas',
            content: (
                <TablesContainer
                    children={<MassInvoicesTable />}
                    title={'Facturación Masiva'}
                    description={
                        'Crea facturas masivamente a partir de un archivo .csv o seleccionando pagos.'
                    }
                    actions={
                            <Row justify="end" style={{ width: '100%' }}>
                                <Button
                                    className="btn-primary"
                                    disabled={!canCreateInvoice}
                                    onClick={() => {
                                        dispatch(openModal('createMassiveInvoicesModalVisible'))
                                    }}
                                >
                                    Crear
                                </Button>
                                <Space wrap></Space>
                            </Row>

                    }
                />
            ),
        },
        {
            key: 'insights',
            title: 'Acciones/Insights',
            content: <InvoicesActionsInsights />,
        },
    ]
    const handleTabChange = (activeKey: string) => {
        let invoiceTab = activeKey
        const path = `/invoices?page=1&ps=10&subtab=${invoiceTab}`
        navigate(path)
    }

    return (
        <>
            <Typography.Text className="h2-bold neutral-1" style={{ marginBottom: '15px' }}>
                Facturación
            </Typography.Text>
            {/* <Typography.Title level={4} className='mediumheader'><CurrencyDollar style={{ width: '18px', height: '18px', marginRight: '6px' }} />Facturación</Typography.Title> */}
            <DynamicTabs
                tabsConfig={tabsConfig}
                activeKey={activeKey}
                defaultActiveKey="I"
                onChange={handleTabChange}
            />
        </>
    )
}
export default InvoicePage
