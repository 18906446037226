import { getFunctions, httpsCallable } from 'firebase/functions'

import { useState, useEffect } from 'react'

interface Subscription {
    id: string
    status: string
    current_period_end: number
    current_period_start: number
    cancel_at_period_end: boolean
    linkToPay?: string
    // Add other subscription fields as needed
}

interface BillingAccount {
    plan?: {
        subscription?: Subscription
    }
}

export const useSubscription = (billingAccount: BillingAccount | null) => {
    const [currentSubscription, setCurrentSubscription] = useState<Subscription | null>(null)
    const getSubscriptionStatus = httpsCallable(getFunctions(), 'retrievecurrentsubscription')

    useEffect(() => {
        if (billingAccount?.plan?.subscription && !currentSubscription?.id) {
            getSubscriptionStatus({
                subscription: billingAccount.plan.subscription,
            })
                .then((response: any) => {
                    setCurrentSubscription({
                        ...response.data?.subscription,
                        linkToPay: response.data?.linkToPay,
                    } as Subscription)
                })
                .catch((error) => {
                    console.error(error)
                })
        } else {
            setCurrentSubscription(null)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [billingAccount])

    return {
        currentSubscription,
        getSubscriptionStatus,
    }
}
