import { ArrowRight, CheckCircle } from '@phosphor-icons/react'
import { Button, Col, Form, Modal, Row, Space, Typography, message } from 'antd'
import { doc, getFirestore, updateDoc } from 'firebase/firestore'
import { usePostHog } from 'posthog-js/react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import circlesGif from '../../../assets/images/circles.gif'
import { InovocieDefaultsForm, InvoiceDefaultsOptions } from '../../components/Forms/AutomatiInvoiceDefaults'
import { RemoveUndefined } from '../../functions/helpers'
import { GetTeamHelper } from '../../context/databaseContextHelpers'
import DefaultTaxesForm from '../../components/Forms/DefaultTaxesForm'
import GlobalInvoiceForm from '../../components/Forms/GlobalInvoiceForm'
import { trackEvent } from '../../analytics/helpers'

interface IDefaultsModalProps {
    isOpen: boolean
    onClose: (step: number) => void
    onStepCompletion: () => void
}

const DefaultsModal = ({ isOpen, onClose, onStepCompletion }: IDefaultsModalProps) => {
    const posthog = usePostHog()
    const fs = getFirestore()
    const [step, setStep] = useState(1)
    const dispatch = useDispatch()

    const { team } = useSelector((state: any) => state.team)
    const { user } = useSelector((state: any) => state.user)

    const renderCheckItems = (items: string[]) =>
        items.map((item, index) => (
            <Col span={24} style={{ marginTop: index > 0 ? '10px' : '0' }} key={index}>
                <Typography.Text className="p-base-regular neutral-2">
                    <CheckCircle className="neutral-1" style={{ marginRight: '10px' }} />
                    {item}
                </Typography.Text>
            </Col>
        ))

    const useFormSetFields = (form: any, fields: any) => {
        useEffect(() => {
            form.setFieldsValue(fields)
        })
    }

    const FormStep = ({ title, description, formContent, onFinish }: any) => {
        const [form] = Form.useForm()

        useFormSetFields(form, formContent.initialValues)

        return (
            <>
                <Row gutter={[10, 10]}>
                    <Col span={24}>
                        <Typography.Text className="h4-bold neutral-1">{title}</Typography.Text>
                    </Col>
                    {description && (
                        <Col span={24}>
                            <Typography.Text className="p-base-regular neutral-3">{description}</Typography.Text>
                        </Col>
                    )}
                    <Col span={24}>
                        <Form form={form} layout="vertical" onFinish={onFinish}>
                            {formContent.form}
                            <Form.Item style={{ textAlign: 'right', marginTop: '16px' }}>
                                <Space>
                                    <Button className="btn-secondary" onClick={() => setStep(step - 1)}>
                                        Atrás
                                    </Button>
                                    <Button className="btn-primary" onClick={() => form.submit()}>
                                        Siguiente
                                    </Button>
                                </Space>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </>
        )
    }

    const handleFinish = async (values: any, type: string, updateFields: any) => {
        try {

            trackEvent({ name: type }, posthog)
            console.log('*** values', values)

            await updateDoc(doc(fs, `teams/${team?.id}`), updateFields)

            GetTeamHelper(team?.id, dispatch)
            setStep(step + 1)
        } catch (error: any) {
            message.error(error?.message ?? 'Ocurrió un error al actualizar la información fiscal')
            return
        }
    }

    const Step1 = () => {
        return (
            <>
                <Row>
                    <Col span={24}>
                        <img src={circlesGif} width="60px" alt="Gigstack animated circles" />
                        <Row align="middle" style={{ marginTop: '10px' }}>
                            <Typography.Text className="h5-bold neutral-1">
                                {' '}
                                Configura tus predeterminados
                            </Typography.Text>
                            <Typography className="h6-regular neutral-3" style={{ marginTop: '10px' }}>
                                Ahora <span className="h6-bold">sigamos con los predeterminados,</span> estos nos
                                ayudarán a facturar automáticamente:
                            </Typography>
                        </Row>
                    </Col>
                </Row>
                <Row style={{ textAlign: 'left', marginTop: '25px' }}>
                    {renderCheckItems([
                        'Con los impuestos adecuados',
                        'Con la información de los productos',
                        'La facturación global',
                    ])}
                </Row>
                <Row style={{ marginTop: '50px', marginBottom: '50px' }}>
                    <Col span={24}>
                        <Button type="primary" icon={<ArrowRight />} onClick={() => setStep(2)}>
                            Comenzar
                        </Button>
                    </Col>
                </Row>
            </>
        )
    }

    const stepData = [
        {
            title: 'Facturación de tus productos o servicios',
            description: '',
            formContent: {
                initialValues: {
                    ...team.defaults,
                },
                form: <InovocieDefaultsForm />,
            },
            onFinish: async (values: any) => {
                let defaultsToUpdate = {
                    ...team.defaults,
                    ...values,
                }

                defaultsToUpdate = RemoveUndefined(defaultsToUpdate)
                handleFinish(values, 'invoice_defaults', { defaults: { ...defaultsToUpdate } })
            },
        },
        {
            title: 'Uso de la facturación y monedas',
            description: '',
            formContent: {
                initialValues: {
                    ...team.defaults,
                },
                form: <InvoiceDefaultsOptions />,
            },
            onFinish: async (values: any) => {
                let defaultsToUpdate = {
                    ...team.defaults,
                    ...values,
                }

                defaultsToUpdate = RemoveUndefined(defaultsToUpdate)
                handleFinish(values, 'invoice_defaults', { defaults: { ...defaultsToUpdate } })
            },
        },
        {
            title: 'Impuestos',
            description: '',
            formContent: {
                initialValues: {
                    ...team.defaults,
                },
                form: <DefaultTaxesForm />,
            },
            onFinish: async (values: any) => {
                setStep(5)
            },
        },
        {
            title: 'Facturación global',
            description: 'Para las conciliaciones automáticas a final de un periodo',
            formContent: {
                initialValues: {
                    ...team.defaults,
                },
                form: <GlobalInvoiceForm showTitle={false} />,
            },
            onFinish: async (values: any) => {
                setStep(6)
            },
        },
    ]

    const Step6 = () => (
        <>
            <Row>
                <Col span={24}>
                    <img src={circlesGif} width="60px" alt="Gigstack animated circles" />
                    <Row align="middle" style={{ marginTop: '10px' }}>
                        <Typography.Text className="h5-bold neutral-1">
                            ¡Listo {user?.firstName}, terminanste los predeterminados!
                        </Typography.Text>
                        <Typography className="h6-regular neutral-3" style={{ marginTop: '10px' }}>
                            ¡Ya estás a un paso! Selecciona tus fuentes de pago para que esté centralizado
                        </Typography>
                    </Row>
                </Col>
            </Row>
            <Row style={{ marginTop: '50px', marginBottom: '50px' }}>
                <Col span={24}>
                    <Button className="btn-primary" icon={<ArrowRight />} onClick={onStepCompletion}>
                        Comenzar
                    </Button>
                </Col>
            </Row>
        </>
    )

    const ToRender = () => {
        switch (step) {
            case 1:
                return <Step1 />
            case 2:
            case 3:
            case 4:
            case 5:
                return <FormStep {...stepData[step - 2]} />
            case 6:
                return <Step6 />
            default:
                return <Step1 />
        }
    }

    return (
        <Modal open={isOpen} width="40%" onCancel={() => onClose(step)} footer={null} className="rounded-md">
            <div style={{ padding: '50px' }}>
                <ToRender />
            </div>
        </Modal>
    )
}

export default DefaultsModal
