import { message } from 'antd'
import { getAuth } from 'firebase/auth'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { usePostHog } from 'posthog-js/react'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { SignedInternalAPIRequest } from '../functions/APIRequest'
// import { retrieveUser, retrieveBillingAccount, retrieveTeam } from '../new-onboarding/auth/utils'
import { doc, getDoc, getFirestore } from 'firebase/firestore'
import { setBillingAccount } from '../context/billingAccountSlice'
import { setInvites, setOnboardinLoading } from '../context/onboardingSlice'
import { setTeam } from '../context/teamsSlice'
import { setUser } from '../context/userSlice'
import { trackEvent } from '../analytics/helpers'

interface InvitesInfo {
    invites: any[]
    loaded: boolean
}

export const handleAcceptInvite = async (
    invite: any,
    dispatch: (action: any) => void,
    posthog: any,
    navigate: (path: string) => void,
) => {
    const auth = getAuth()

    try {
        // dispatch(setOnboardinLoading(true))
        const body = {
            token: invite.token,
            action: 'accept',
            currentUser: auth.currentUser,
            inviteId: invite.id,
            teamId: invite.teamId,
        }
    
        trackEvent({ name: 'accepted_invite', metadata: { team: invite.teamId } }, posthog)
        await SignedInternalAPIRequest({ ...body }, 'handleSeats/v1/action', auth.currentUser)
        message.success('Invitación aceptada')

        const u = await (await getDoc(doc(getFirestore(), 'users', auth?.currentUser?.uid ?? ''))).data()

        const teamD = await (await getDoc(doc(getFirestore(), 'teams', u?.lastTeamViewed ?? u?.teams[0]))).data()
        const ba = await (
            await getDoc(doc(getFirestore(), 'billingAccounts', u?.lastBillingAccountViewed ?? u?.billingAccounts[0]))
        ).data()
        dispatch(setUser(u))
        dispatch(setTeam(teamD))
        dispatch(setBillingAccount(ba))
        window.location.reload()
    } catch (error: any) {
        message.error(error.message)
    } finally {
        dispatch(setOnboardinLoading(false))
    }
}

export const fetchInvites = async () => {
    try {
        const functions = getFunctions()
        const checkInvites = httpsCallable(functions, 'checkinvitesonboarding')
        await new Promise((resolve) => setTimeout(resolve, 1000))
        const result: any = await checkInvites()
        console.log('Result: ', result)
        return result?.data?.invites || []
    } catch (error) {
        console.error(error)
    }
}

const useInvites = () => {
    // ({ handleSubmitOnboarding }: { handleSubmitOnboarding?: () => void }) => {
    const posthog = usePostHog()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const functions = getFunctions()
    const checkInvites = httpsCallable(functions, 'checkinvitesonboarding')

    const [invitesInfo, setInvitesInfo] = useState<InvitesInfo>({ invites: [], loaded: false })
    const [loading, setLoading] = useState<string[]>([])
    const auth = getAuth()

    // const { createBillingAccount } = useBillingAccount()

    const getInvites = useCallback(async () => {
        try {
            await new Promise((resolve) => setTimeout(resolve, 1000))
            const result: any = await checkInvites()
            setInvitesInfo({ invites: result.data?.invites || [], loaded: true })
            dispatch(setInvites(result.data?.invites ?? []))
            return result.data
        } catch (error) {
            console.error(error)
            setInvitesInfo((prev) => ({ ...prev, loaded: true }))
            dispatch(setInvites([]))
        }
    }, [checkInvites, dispatch])

    useEffect(() => {
        if (!invitesInfo.loaded) {
            console.log('')
            getInvites()
        }
    }, [getInvites, invitesInfo.loaded])

    const handleAcceptInvite = async (invite: any) => {
        try {
            setLoading((prev) => [...prev, invite.id])
            const body = {
                token: invite.token,
                action: 'accept',
                currentUser: auth.currentUser,
                inviteId: invite.id,
                teamId: invite.teamId,
            }
            trackEvent({ name: 'accepted_invite', metadata: { team: invite.teamId } }, posthog)
            await SignedInternalAPIRequest({ ...body }, 'handleSeats/v1/action', auth.currentUser)
            message.success('Invitación aceptada')

            const u = await (await getDoc(doc(getFirestore(), 'users', auth?.currentUser?.uid ?? ''))).data()

            const teamD = await (await getDoc(doc(getFirestore(), 'teams', u?.lastTeamViewed ?? u?.teams[0]))).data()
            const ba = await (
                await getDoc(
                    doc(getFirestore(), 'billingAccounts', u?.lastBillingAccountViewed ?? u?.billingAccounts[0]),
                )
            ).data()
            dispatch(setUser(u))
            dispatch(setTeam(teamD))
            dispatch(setBillingAccount(ba))

            navigate('/')
        } catch (error: any) {
            message.error(error.message)
        } finally {
            setLoading((prev) => prev.filter((item) => item !== invite.id))
        }
    }

    const handleRejectInvite = async (invite: any) => {
        try {
            setLoading((prev) => [...prev, invite.id])
            const body = {
                token: invite.token,
                action: 'decline',
                currentUser: auth.currentUser,
                inviteId: invite.id,
                teamId: invite.teamId,
            }
            await SignedInternalAPIRequest({ ...body }, 'handleSeats/v1/action', auth.currentUser)
            message.success('Invitación rechazada')
            await getInvites()
        } catch (error: any) {
            message.error(error.message)
        } finally {
            setLoading((prev) => prev.filter((item) => item !== invite.id))
        }
    }

    // const reloadInvites = useCallback(async () => {
    //     setInvitesInfo({ invites: [], loaded: false })
    //     await getInvites()
    // }, [getInvites])

    return {
        invitesInfo,
        loading,
        handleAcceptInvite,
        handleRejectInvite,
        getInvites,
        // reloadInvites, // Expose the new function
    }
}

export default useInvites
