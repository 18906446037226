import { Col, Progress, Row, Typography } from 'antd'
import { useMissingOnboardingStepsCards, useNoticeCards } from '../../customHooks/useNoticeBoard'
import NoticeCard from './NoticeCard'
import { memo, useEffect, useState } from 'react'
import MissingOnboardingStepsCard from './MissingOnboardingStepsCard'
import { ClassicSpinner } from 'react-spinners-kit'

// Define the props interface (even if empty)
interface NoticeBoardProps {
    // Add any props here if needed
}

const NoticeBoard = memo<NoticeBoardProps>(function MemoizedNoticeBoard() {
    const { noticeCards } = useNoticeCards()
    const { missingOnboardingSteps, showOnboardingCards, loading } = useMissingOnboardingStepsCards()
    const [percent, setPercent] = useState(0)

    useEffect(() => {
        if (showOnboardingCards) {
            const completedSteps = missingOnboardingSteps.filter((step) => step.isCompleted)
            setPercent((completedSteps.length / missingOnboardingSteps.length) * 100)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [missingOnboardingSteps])

    if (noticeCards.length === 0 && !showOnboardingCards) {
        return null
    }

    return (
        <Col xs={23} lg={23}>
            <div
                // Color and title are determined by whether onboarding steps are missing
                className={`rounded-md ${showOnboardingCards ? 'bg-neutral-8' : 'bg-white'}`}
                style={{
                    padding: '20px',
                }}
            >
                <Row justify="space-between" align="middle" wrap>
                    <div className="d-flex flex-column">
                        <div id="newFeats">
                            <Typography.Text className="h3-bold">
                                {showOnboardingCards ? '¡Te damos la bienvenida a gigstack!' : 'Avisos importantes'}
                            </Typography.Text>
                        </div>
                        {showOnboardingCards && (
                            <Typography.Text className="p-base-regular">
                                Termina de configurar tu experiencia con los siguientes pasos:
                            </Typography.Text>
                        )}
                    </div>
                </Row>
                {loading ? (
                    <Row justify="center" align="middle" style={{ minHeight: '130px' }}>
                        <ClassicSpinner size={30} color="var(--primary-3)" />
                    </Row>
                ) : (
                    <>
                        {showOnboardingCards && (
                            <div>
                                <Row style={{ display: 'flex', justifyContent: 'end' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', width: '350px' }}>
                                        <span style={{ width: '60%' }}>
                                            <Typography.Text className="p-base-bold">
                                                {percent}% completado
                                            </Typography.Text>
                                        </span>
                                        <Progress
                                            percent={percent}
                                            showInfo={false}
                                            strokeColor={'var(--primary-9)'}
                                            size={[150, 15]}
                                        />
                                    </div>
                                </Row>
                                <Row
                                    style={{
                                        minHeight: '130px',
                                        paddingTop: '10px',
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                    }}
                                >
                                    {missingOnboardingSteps.map((card, i) => (
                                        <MissingOnboardingStepsCard key={card.id} card={card} index={i} />
                                    ))}
                                </Row>
                            </div>
                        )}
                        {noticeCards.length > 0 && (
                            <Row>
                                {noticeCards.map((card) => (
                                    <NoticeCard key={card.id} card={card} />
                                ))}
                            </Row>
                        )}
                    </>
                )}
            </div>
        </Col>
    )
})

export default NoticeBoard
