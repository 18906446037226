import React, { useEffect, useState } from 'react'
import { Row, Col, Table, Typography, Space, Tooltip, message } from 'antd'
import { getFunctions, httpsCallable } from 'firebase/functions'
import moment from 'moment'
import { CheckCircle, XCircle } from '@phosphor-icons/react'
import { getAuth } from 'firebase/auth'
import { SignedInternalAPIRequest } from '../../functions/APIRequest'
import { doc, getDoc, getFirestore } from 'firebase/firestore'
import { useDispatch, useSelector } from 'react-redux'
import { setUser } from '../../context/userSlice'
import { MetroSpinner } from 'react-spinners-kit'

import { setTeam } from '../../context/teamsSlice'
import { usePostHog } from 'posthog-js/react'
import { trackEvent } from '../../analytics/helpers'

interface PendingInvitesElementProps {
    customHeader?: any
    invitesInfo: {
        invites: any[]
        loaded: boolean
    }
    setInvitesInfo: ({ invites, loaded }: { invites: any[]; loaded: boolean }) => void
}

const PendingInvitesElement: React.FC<PendingInvitesElementProps> = ({ customHeader, invitesInfo, setInvitesInfo }) => {
    const posthog = usePostHog()
    const { user } = useSelector((state: any) => state.user)
    const [loading, setLoading] = useState<any[]>([])
    const functions = getFunctions()
    const auth = getAuth()
    const checkInvites = httpsCallable(functions, 'checkinvitesonboarding')
    const dispatch = useDispatch()

    const getInvites = async () => {
        try {
            const result: any = await checkInvites()
            return setInvitesInfo({ invites: result.data?.invites || [], loaded: true })
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        if (!invitesInfo.loaded) {
            getInvites()
        }

        return () => {}
        // eslint-disable-next-line
    }, [invitesInfo])

    const handleAcceptInvite = async ({ invite }: { invite: any }) => {
        try {
            setLoading(loading.concat(invite.id))
            const body = {
                token: invite.token,
                action: 'accept',
                currentUser: auth.currentUser,
                inviteId: invite.id,
                teamId: invite.teamId,
            }
            trackEvent({
                name: 'accepted_invite',
                metadata: {
                    team: invite.teamId,
                }
            }, posthog)
            await SignedInternalAPIRequest({ ...body }, 'handleSeats/v1/action', auth.currentUser)
            message.success('Invitación aceptada')
            setLoading(loading.filter((item: any) => item !== invite.id))
            const rawUser = await getDoc(doc(getFirestore(), 'users', user.uid))
            dispatch(setUser(rawUser.data()))
            const tmd = await getDoc(doc(getFirestore(), 'teams', invite.teamId))
            dispatch(setTeam(tmd.data()))
            const ni: any = (await checkInvites()).data
            setInvitesInfo({
                invites: ni.data?.invites ?? [],
                loaded: true,
            })
        } catch (error: any) {
            message.error(error.message)
            setLoading(loading.filter((item: any) => item !== invite.id))
        }
    }

    const handleRejectInvite = async ({ invite }: { invite: any }) => {
        console.log(invite)

        try {
            setLoading(loading.concat(invite.id))
            const body = {
                token: invite.token,
                action: 'decline',
                currentUser: auth.currentUser,
                inviteId: invite.id,
                teamId: invite.teamId,
            }
            await SignedInternalAPIRequest({ ...body }, 'handleSeats/v1/action', auth.currentUser)
            message.success('Invitación rechazada')
            setLoading(loading.filter((item: any) => item !== invite.id))
            const ni: any = (await checkInvites()).data
            setInvitesInfo({
                invites: ni.data?.invites ?? [],
                loaded: true,
            })
        } catch (error: any) {
            message.error(error.message)
            setLoading(loading.filter((item: any) => item !== invite.id))
        }
    }

    return (
        <Row>
            {customHeader && customHeader}
            <Col xs={24} style={{ marginTop: '25px' }}>
                <Table
                    dataSource={invitesInfo.invites}
                    columns={[
                        {
                            title: 'Team',
                            dataIndex: 'teamName',
                            key: 'teamName',
                            render: (name: any) => {
                                return <Typography.Text className="smallparagraph">{name}</Typography.Text>
                            },
                        },
                        {
                            title: 'Fecha de invitación',
                            dataIndex: 'timestamp',
                            key: 'timestamp',
                            render: (timestamp: any) => {
                                return (
                                    <Typography.Text className="smallparagraph">
                                        {moment(timestamp).format('DD MMM YYYY HH:mm')}
                                    </Typography.Text>
                                )
                            },
                        },
                        {
                            title: 'Fecha de vencimiento',
                            dataIndex: 'expiring',
                            key: 'expiring',
                            render: (timestamp: any) => {
                                return (
                                    <Typography.Text className="smallparagraph">
                                        {moment(timestamp).format('DD MMM YYYY HH:mm')}
                                    </Typography.Text>
                                )
                            },
                        },
                        {
                            title: '',
                            dataIndex: 'action',
                            key: 'action',
                            render: (action: any, record: any) => {
                                const id = record?.id ?? ''
                                return loading.includes(id) ? (
                                    <MetroSpinner size={20} color="#1890ff" />
                                ) : (
                                    <Space>
                                        <Tooltip title="Rechazar invitación">
                                            <XCircle
                                                onClick={() => handleRejectInvite({ invite: record })}
                                                className="clickable"
                                                size={20}
                                                color="#ff4d4f"
                                            />
                                        </Tooltip>
                                        <Tooltip title="Aceptar invitación">
                                            <CheckCircle
                                                onClick={() => handleAcceptInvite({ invite: record })}
                                                className="clickable"
                                                size={20}
                                                color="#52c41a"
                                            />
                                        </Tooltip>
                                    </Space>
                                )
                            },
                        },
                    ]}
                />
            </Col>
        </Row>
    )
}

export default PendingInvitesElement
