import { AutoComplete, Checkbox, Form, Typography } from 'antd'
import React, { useState } from 'react'
import { TextInput } from '../Forms/Inputs'
import { CollectionType, getSearchData } from '../../search/SearchComponent'
import { useSelector } from 'react-redux'

const formatLabel = (hit: any) => {
    if (hit.document.similar) {
        return `${hit.document.code}: ${hit.document.description} (${hit.document.similar})`
    } else {
        return `${hit.document.code}: ${hit.document.description}`
    }
}

export default function ProductKeySearch() {
    const [showSearch, setShowSearch] = useState(false)
    const [productKeys, setProductKeys] = useState([])
    const [loading, setLoading] = useState(false)
    const [value, setValue] = useState('')

    const { team } = useSelector((state: any) => state.team)

    const handleCheckbox = (e: any) => {
        if (e.target.checked) {
            setValue('')
            setProductKeys([])
        }
        setShowSearch(e.target.checked)
    }

    const handleSearch = async (e: any) => {
        if (showSearch) {
            const searchParams = {
                collection: 'product_keys' as CollectionType,
                query: e.toString(),
                team,
                setLoading: setLoading,
            }

            const searchData: any = await getSearchData(searchParams)

            if (searchData.hits) {
                const autocompleteOptions = searchData.hits.map((hit: any) => ({
                    value: hit.document.code,
                    label: formatLabel(hit),
                }))
                setProductKeys(autocompleteOptions)
            }
        } else return
    }

    const handleSelect = (e: any) => {
        setValue(e)
        setProductKeys([])
    }

    return (
        <div>
            <div>
                <Typography className="p-base-bold" style={{marginBottom: "10px"}}>Código del producto:</Typography>
                {showSearch ? (
                    <div style={{ marginBottom: "7px"}}>
                        <Form.Item
                            name="product_key"
                            rules={[{ required: true, message: 'Por favor selecciona un código de producto' }]}
                        >
                            <AutoComplete
                                style={{ width: "100%" }}
                                options={loading ? [{ value: 'Cargando...' }] : productKeys.length > 0 ? productKeys : []}
                                placeholder="Servicios contables, Material médico, etc..."
                                onSearch={handleSearch}
                                onChange={(value) => setValue(value)}
                                onSelect={handleSelect}
                                value={value}
                            />
                        </Form.Item>
                        <Typography className="p-small-regular neutral-3" style={{ marginLeft: "3px", padding:"0"}}>
                            Ingresa tu búsqueda.
                        </Typography>
                    </div>
                ) : (
                    <TextInput
                        rules={[
                            {
                                required: true,
                                message: 'Por favor ingresa el código del producto',
                            },
                        ]}
                        name={'product_key'}
                        description="Código del producto utilizado para el SAT."
                        placeholder="01010101"
                    />
                )}
                <div>
                    <Checkbox onChange={handleCheckbox}>
                        <Typography className="p-small-regular">Buscar en catálogo del SAT</Typography>
                    </Checkbox>
                </div>
            </div>
        </div>
    )
}
