import { Button, Layout, Row, Typography, message } from 'antd'
import { arrayUnion, doc, getDoc, getFirestore, updateDoc } from 'firebase/firestore'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useAuth, useFirestoreDocDataOnce } from 'reactfire'
import Loader from '../appStates/LoadingView'
import FallbackErrorComponent from '../components/Errors/FallbackErrorComponent'
import { finishStripeConnection } from '../components/Integrations/stripeHelpers'
import SiderMenu from '../components/Menu/Menus'
import Header from '../components/UI/Header'
import ElementHolder from '../drawers/ElementHolder'
import { handleUrlParams } from '../functions/UrlParams'
import ClientsPage from '../pages/ClientsPage'
import ClientViewDetail from '../pages/ClientsViewDetail'
import CollectionsPage from '../pages/CollectionsPage'
import HomePage from '../pages/HomePage'
import IntegrationsPage from '../pages/IntegrationsPage'
import { InvoiceDetails } from '../pages/InvoiceDetailsView'
import InvoicePage from '../pages/InvoicePage'
import PaymentDetailsView from '../pages/PaymentDetailsView'
import PaymentsPage from '../pages/PaymentPage'
import ProductsPage from '../pages/ProductsPage'
import ReceiptsPage from '../pages/ReceiptsPage'
import ReceiptsViewDetail from '../pages/ReceiptsViewDetail'
import SettingsPage from '../pages/SettingsPage'
// import TestModeSignal from "../components/ComponentIndividual/TestModeSignal";
import { ArrowRight, X } from '@phosphor-icons/react'
import RelatingElementsSignal from '../components/Relations/RelatingElementsSignal'
import TestModeBanner from '../components/UI/TestModeBanner'
import GettingStarted from '../pages/GettingStarted'
import { useSubscription } from '../customHooks/subscriptionHook'
import PaymentErrorModal from '../modals/PaymentErrorModal'
import VendorsPage from '../pages/VendorsPage'

const Banner = ({
    showBanner,
    setShowBanner,
    bannerInfo,
    setBannerInfo,
}: {
    showBanner: boolean
    setShowBanner: (v: boolean) => void
    bannerInfo: any
    setBannerInfo: (v: any) => void
}) => {
    const auth = useAuth()
    const { user } = useSelector((state: any) => state.user)

    const getBannerInfo = async () => {
        try {
            const ban = await getDoc(doc(getFirestore(), 'banners', 'main'))
            if (
                ban.data()?.active &&
                ban.data()?.validUntil > moment().valueOf() &&
                !(ban.data()?.hiddenFor ?? []).includes(auth.currentUser?.uid ?? 'mnot')
            ) {
                setBannerInfo(ban.data())
                setShowBanner(true)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getBannerInfo()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (!showBanner) {
        return null
    }

    return (
        <div
            style={{
                position: 'fixed',
                top: '0',
                width: '100%',
                backgroundColor: bannerInfo?.color ?? '#8666ff',
                color: '#fff',
                height: bannerInfo?.height ?? '55px',
                zIndex: '9999',
                boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}
        >
            <Row align="middle" justify="center" style={{ margin: '0px 0px 0px 20px' }}>
                <img
                    src={bannerInfo?.imageUrl}
                    alt="banner"
                    style={{
                        height: '40px',
                        width: 'auto',
                        maxWidth: '100%',
                        verticalAlign: 'middle',
                        display: 'inline-block',
                        position: 'relative',
                        margin: '0px 10px',
                        borderRadius: '50%',
                    }}
                />
                <Typography.Text style={{ color: 'white' }}>
                    {bannerInfo?.message?.replace('{name}', user?.name ?? '')}
                    {bannerInfo?.CTAText && (
                        <Button
                            type="link"
                            onClick={() => {
                                window.open(bannerInfo?.CTALink, '_blank')
                            }}
                            style={{ color: 'white', padding: '5px' }}
                        >
                            {bannerInfo?.CTAText} <ArrowRight size={16} />
                        </Button>
                    )}
                </Typography.Text>
            </Row>
            {bannerInfo?.closable && (
                <X
                    className="clickable"
                    style={{ marginRight: '20px' }}
                    onClick={async () => {
                        setShowBanner(false)
                        try {
                            await updateDoc(doc(getFirestore(), 'banners', 'main'), {
                                hiddenFor: arrayUnion(auth.currentUser?.uid ?? 'mnot'),
                            })
                        } catch (error) {
                            console.log(error)
                        }
                    }}
                />
            )}
        </div>
    )
}

const MainLayout = ({ element }: { element: string }) => {
    const dispatch = useDispatch()
    const auth = useAuth()
    const fs = getFirestore()

    const { status: userStatus, data: userData } = useFirestoreDocDataOnce(
        doc(fs, 'users', auth.currentUser?.uid ?? 'mnot'),
        {
            idField: 'uid',
        },
    )
    const { team, teams } = useSelector((state: any) => state.team)
    const { billingAccount } = useSelector((state: any) => state.billingAccount)
    const { testmode } = useSelector((state: any) => state.data)

    const [showBanner, setShowBanner] = useState(false)
    const [bannerInfo, setBannerInfo] = useState({} as any)

    let { tab } = useParams()
    const { currentSubscription } = useSubscription(billingAccount)

    const renderElement = (element?: string) => {
        switch (tab) {
            case 'home':
                return <HomePage />
            case 'payments':
                return <PaymentsPage />
            case 'paymentDetails':
                return <PaymentDetailsView />
            case 'receipts':
                return <ReceiptsPage />
            case 'receiptDetails':
                return <ReceiptsViewDetail />
            case 'invoices':
                return <InvoicePage />
            case 'invoiceDetails':
                return <InvoiceDetails />
            case 'collections':
                return <CollectionsPage />
            case 'products':
                return <ProductsPage />
            case 'clients':
                return <ClientsPage />
            case 'vendors':
                return <VendorsPage />
            case 'clientDetails':
                return <ClientViewDetail />
            case 'integrations':
                return <IntegrationsPage />
            case 'settings':
                return <SettingsPage />
            case 'gettingStarted':
                return <GettingStarted />

            default:
                return <HomePage />
        }
    }
    const [collapsed, setCollapsed] = useState(false)

    useEffect(() => {
        const handleResize = () => {
            setCollapsed(window.innerWidth < 780)
        }

        window.addEventListener('resize', handleResize)
        handleResize()
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    useEffect(() => {
        handleUrlParams(dispatch)
    }, [dispatch])

    const [stripeLoaded, setStripeLoaded] = useState(false)
    useEffect(() => {
        if (!stripeLoaded && team?.id) {
            finishStripeConnection({
                setLoading: () => {
                    return message.loading('Conectando con Stripe', 10000)
                },
                authUser: auth.currentUser,
            })
            setStripeLoaded(true)
        }

        // if (userData) {
        //     window.Intercom('boot', {
        //         app_id: 'acgd4n2q',
        //         email: userData?.email,
        //         created_at: moment(userData?.timestamp).unix(),
        //         name: userData?.name,
        //         user_id: userData?.uid,
        //         user_hash: userData?.intercomHash,
        //         avatar: {
        //             type: 'avatar',
        //             image_url: userData?.brand?.logo ?? userData?.profilePic,
        //         },
        //         billing_account_id: billingAccount?.id,
        //         billing_account_name: billingAccount?.legalName ?? billingAccount?.legal_name ?? billingAccount?.name,
        //         team_id: team?.id,
        //         team_name: team?.brand?.alias,
        //         facturapi_completed: team?.facturapi?.completed ?? false,
        //         stripe_completed: team?.stripe?.completed ?? false,
        //         openpay_completed: team?.openpay?.completed ?? false,
        //         whmcs_completed: team?.whmcs?.completed ?? false,
        //         stripe_auto_invoice_active: team?.stripe?.automaticInvoicing ?? false,
        //         Credits: billingAccount?.credits ?? 0,
        //         payasyougo: billingAccount?.payAsYouGo ?? false,
        //         Membership: '',
        //         custom_launcher_selector: '#intercomButtonOpen',
        //     })
        // }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [team?.id])

    // useEffect(() => {
    //     if (userData?.uid && team?.id) {
    //         dispatch(
    //             setData({
    //                 item: 'testmode',
    //                 data: (userData?.interfaceSettings ?? {})[team?.id]?.testmode ?? false,
    //             }),
    //         )
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [userData?.uid, team?.id, billingAccount?.id, teams, userStatus])

    if (!userData?.uid && !team?.id && !billingAccount?.id && !teams && userStatus !== 'success') {
        return <Loader loading={true} />
    }

    const blockPaymentError =
        currentSubscription?.status === 'unpaid' && (billingAccount?.allowUntil ?? 0) < moment().valueOf()

    return (
        <ErrorBoundary FallbackComponent={(props) => <FallbackErrorComponent {...props} />} onReset={(details) => {}}>
            <ElementHolder />
            <PaymentErrorModal open={blockPaymentError} />
            <Banner
                showBanner={showBanner}
                setShowBanner={(v: boolean) => setShowBanner(v)}
                bannerInfo={bannerInfo}
                setBannerInfo={(v: any) => setBannerInfo(v)}
            />
            {testmode && <TestModeBanner />}
            {/* {testmode && <TestModeSignal />} */}
            <RelatingElementsSignal />

            <Layout style={{ marginTop: testmode ? '32px' : '0px', paddingBottom: testmode ? '32px' : '0px' }}>
                <Layout.Sider
                    trigger={null}
                    collapsible
                    collapsed={collapsed}
                    collapsedWidth={60}
                    style={{
                        position: 'fixed',
                        height: '100vh',
                        left: 0,
                        zIndex: 100,
                        overflow: 'hidden',
                    }}
                >
                    <SiderMenu />
                </Layout.Sider>
                <Layout>
                    <Layout.Header
                        style={{
                            padding: 0,
                            backgroundColor: 'white',
                            transition: 'margin-left 0.3s',

                            position: 'fixed',
                            width: '100%',
                            height: '63px',
                            zIndex: 90,
                            top: testmode ? '32px' : '0px',
                            borderBottom: '1px solid var(--neutral-4)',
                        }}
                    >
                        <Header />
                    </Layout.Header>
                    <Layout.Content
                        style={{
                            backgroundColor: 'white',
                            padding: '20px',
                            minHeight: '93vh',
                            transition: 'margin-left 0.3s',
                            marginLeft: collapsed ? '60px' : '200px',
                            marginTop: '64px',
                        }}
                    >
                        {renderElement(element)}
                    </Layout.Content>
                </Layout>
            </Layout>
        </ErrorBoundary>
    )
}

export default MainLayout
