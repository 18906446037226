import { arrayUnion, doc, getDoc, getFirestore, updateDoc } from 'firebase/firestore'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from 'reactfire'
import Loader from '../appStates/LoadingView'
import { setBillingAccount } from '../context/billingAccountSlice'
import { GetBATeamsHelper } from '../context/databaseContextHelpers'
import { setDataLoaded } from '../context/onboardingSlice'
import { setTeam } from '../context/teamsSlice'
import { setUser } from '../context/userSlice'
import { useOnboardingCheck } from '../customHooks/useOnboardingCheck'
import { preserveQueryParams } from '../functions/helpers'
import moment from 'moment'
import { message } from 'antd'

const loadTally = () => {
    return new Promise((resolve, reject) => {
        if ((window as any).Tally) {
            return resolve(true)
        }
        console.log('loading tally')
        const script = document.createElement('script')
        script.src = 'https://tally.so/widgets/embed.js'
        script.async = true
        script.onload = () => resolve(true)
        document.body.appendChild(script)
    })
}
export const openTallyForm = async (userData: any) => {
    if (!(window as any).Tally) {
        await loadTally()
    }

    if ((window as any).Tally && typeof (window as any).Tally.openPopup === 'function') {
        console.log('open popup')
        ;(window as any).Tally.openPopup('w4P1eB', {
            layout: 'modal',
            width: 600,
            hiddenFields: {
                userid: userData.id || userData.uid,
            },
            onOpen: () => {
                // The popup was opened, mark the form as seen
                // ...
                // console.log('popup opened')
            },
            onClose: async () => {
                // The popup was closed
                await updateDoc(doc(getFirestore(), 'users', userData.id || userData.uid), {
                    nps: {
                        lastShown: moment().valueOf(),
                        event: 'closed',
                    },
                })
                // ...
            },
            onSubmit: async (payload: any) => {
                // Form was submitted, use the answers payload in your application
                // ...
                ;(window as any).Tally.closePopup('w4P1eB')
                message.success('¡Gracias por tu respuesta!')
                await updateDoc(doc(getFirestore(), 'users', userData.id || userData.uid), {
                    nps: {
                        lastShown: moment().valueOf(),
                        event: 'submitted',
                        answers: payload,
                    },
                    npsLogs: arrayUnion({
                        timestamp: moment().valueOf(),
                        answers: payload?.answers?.[0]?.fields?.[0]?.value ?? '',
                    }),
                })
            },
            onError: (error: any) => {
                console.error('Error opening Tally popup:', error)
            },
        })
    }
}

const AuthScreensWrapper = ({ children }: { children: JSX.Element }) => {
    const auth = useAuth()
    const fs = getFirestore()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const { dataLoaded } = useSelector((state: any) => state.onboarding)
    const { needsOnboarding, loading: onboardingLoading } = useSelector((state: any) => state.onboarding)
    const { user } = useSelector((state: any) => state.user)

    const { onboardingStep } = useOnboardingCheck()
    const [isLoading, setIsLoading] = useState(true)
    const [retries, setRetries] = useState(0)

    const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search])
    const redirect = useMemo(() => searchParams.get('redirect'), [searchParams])

    const handlePerserveQueryParams = useCallback(
        (url: string) => preserveQueryParams(url, searchParams),
        [searchParams],
    )

    useEffect(() => {
        const loadUserData = async () => {
            if (!auth.currentUser?.uid) return
            try {
                if (!isLoading && user) return

                const userData = await getDoc(doc(fs, 'users', auth.currentUser.uid))
                let teamData
                let billingAccountData

                if (userData.exists()) {
                    try {
                        // const userD = userData.data()

                        const { lastTeamViewed, lastBillingAccountViewed } = userData.data()
                        dispatch(setUser(userData.data()))

                        if (lastTeamViewed) {
                            teamData = await getDoc(doc(fs, 'teams', lastTeamViewed))
                        }

                        if (lastBillingAccountViewed) {
                            billingAccountData = await getDoc(doc(fs, 'billingAccounts', lastBillingAccountViewed))
                        }

                        if (teamData?.exists()) {
                            dispatch(setTeam(teamData.data()))
                        }
                        if (billingAccountData?.exists()) {
                            dispatch(setBillingAccount(billingAccountData.data()))
                            GetBATeamsHelper(userData.id, dispatch)
                        }
                        //TODO: SET INTERCOM
                        // window.Intercom('boot', {
                        //     app_id: 'acgd4n2q',
                        //     email: userD?.email,
                        //     created_at: moment(userD?.timestamp).unix(),
                        //     name: userD?.name,
                        //     user_id: userD?.uid,
                        //     user_hash: userD?.intercomHash,
                        //     avatar: {
                        //         type: 'avatar',
                        //         image_url: teamData?.data()?.brand?.logo ?? userD?.profilePic,
                        //     },
                        //     billing_account_id: teamData?.data()?.billingAccount,
                        //     billing_account_name:
                        //         billingAccountData?.data()?.legalName ?? billingAccountData?.data()?.name,
                        //     team_id: teamData?.id,
                        //     team_name: teamData?.data()?.brand?.alias,
                        //     facturapi_completed: teamData?.data()?.facturapi?.completed ?? false,
                        //     stripe_completed: teamData?.data()?.stripe?.completed ?? false,
                        //     openpay_completed: teamData?.data()?.openpay?.completed ?? false,
                        //     whmcs_completed: teamData?.data()?.whmcs?.completed ?? false,
                        //     stripe_auto_invoice_active: teamData?.data()?.stripe?.automaticInvoicing ?? false,
                        //     Credits: billingAccountData?.data()?.credits ?? 0,
                        //     payasyougo: billingAccountData?.data()?.payAsYouGo ?? false,
                        //     Membership: '',
                        //     custom_launcher_selector: '#intercomButtonOpen',
                        // })
                        dispatch(setDataLoaded(true))
                        setTimeout(() => setIsLoading(false), 1000)
                    } catch (error) {
                        console.log('🚀 ~ loadUserData ~ error:', error)
                    }
                } else {
                    if (retries < 5) {
                        setRetries(retries + 1)
                    }
                }
            } catch (error) {
                console.error('Error loading user data:', error)
            }
        }

        loadUserData()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataLoaded, auth.currentUser?.uid, retries])

    useEffect(() => {
        if (dataLoaded && !onboardingLoading && !isLoading) {
            // if (!needsOnboarding) navigate(preserveQueryParams('/'))
            if (needsOnboarding) navigate(handlePerserveQueryParams('/onboarding'))
            if (redirect) navigate(handlePerserveQueryParams(redirect))
        }
    }, [
        dataLoaded,
        onboardingLoading,
        isLoading,
        needsOnboarding,
        onboardingStep,
        redirect,
        navigate,
        handlePerserveQueryParams,
    ])

    if (isLoading || onboardingLoading) {
        return <Loader loading={true} />
    }

    return children
}

export default AuthScreensWrapper
