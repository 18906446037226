import { ArrowLeft, ArrowRight } from '@phosphor-icons/react'
import { Alert, Button, Col, Form, FormInstance, message, Row, Space, Tooltip, Typography } from 'antd'
import { doc, getFirestore, updateDoc } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { SelectInput, TextInput } from '../../../components/Forms/Inputs'
import { setData } from '../../../context/dataSlice'
import { openModal } from '../../../context/modalsSlice'
import { ONBOARDING_STEP, setStepLoading } from '../../../context/onboardingSlice'
import { setTeam } from '../../../context/teamsSlice'
import { preserveQueryParams, taxRegimes } from '../../../functions/helpers'
import { AddressModal } from '../../../modals/AddressModal'
import StepLayout from '../StepsLayout'
import { InvoicePreview } from './DefaultsStepsSidebar'
import { UploadCSFInput } from './UploadCSF'
import { usePostHog } from 'posthog-js/react'
import { trackEvent } from '../../../analytics/helpers'

interface FiscalInformationStepProps {
    form: FormInstance
    onStepCompleted: () => void
    renderButtons: () => React.ReactNode
}

export const FiscalInformationStep = ({ form, onStepCompleted, renderButtons }: FiscalInformationStepProps) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const posthog = usePostHog()
    const { currentStep } = useSelector((state: any) => state.onboarding)
    const url = new URL(window.location.href)
    const params = new URLSearchParams(url.search)

    const { fiscalInformationOnb } = useSelector((state: any) => state.data)
    const { team } = useSelector((state: any) => state.team)

    const [error, setError] = useState<string | null>(null)
    const [infSource, setInfSource] = useState<'csf' | 'manual' | null>(null)
    const [showFiscalForm, setShowFiscalForm] = useState(team?.legal_name ? true : false)

    const [loadingInfo, setLoadingInfo] = useState(false)

    useEffect(() => {
        trackEvent({ name: 'onboarding_fiscal_section_viewed', metadata: { v: 'v2' } }, posthog)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const loadTeamData = async () => {
            try {
                if (team) {
                    form.setFieldsValue({
                        legal_name: team.legal_name,
                        rfc: team.rfc,
                        tax_system: team.tax_system,
                        address: team.address,
                    })
                }

                dispatch(
                    setData({
                        item: 'fiscalInformationOnb',
                        data: {
                            legal_name: team.legal_name,
                            rfc: team.rfc,
                            tax_system: team.tax_system,
                            address: team.address,
                            addressStr: `${team.address.street || ''} #${team.address.exterior || ''}, ${team.address.neighborhood || ''}, ${team.address.city || ''}, ${team.address.zip || ''} ${team.address.state || ''}`,
                        },
                    }),
                )
            } catch (err) {
                console.error('Error loading user data:', err)
                setError('No se pudo cargar la información del usuario. Por favor, intenta de nuevo.')
            }
        }

        loadTeamData()
    }, [dispatch, form, team])

    useEffect(() => {
        if (fiscalInformationOnb && infSource === 'csf') {
            form.setFieldsValue(fiscalInformationOnb)
        }
        if (currentStep === ONBOARDING_STEP.FISCAL_DATA && team?.address?.zip) {
            const addressStr: string = `${team.address.street || ''} #${team.address.exterior || ''}, ${team.address.neighborhood || ''}, ${team.address.city || ''}, ${team.address.zip || ''} ${team.address.state || ''}`
            form.setFieldsValue({
                addressStr,
            })
        }
    }, [fiscalInformationOnb, form, infSource, currentStep, team?.address])

    const handleFinish = async () => {
        setError(null)
        dispatch(setStepLoading(true))
        try {
            await form.validateFields()

            const valuesToUpdate = {
                legal_name: fiscalInformationOnb.legal_name,
                legalName: fiscalInformationOnb.legal_name,
                tax_system: fiscalInformationOnb.tax_system,
                rfc: fiscalInformationOnb.rfc,
                address: fiscalInformationOnb.address,
            }

            await updateDoc(doc(getFirestore(), 'teams', team.id), valuesToUpdate)
            dispatch(setTeam({ ...team, ...valuesToUpdate }))
            dispatch(setStepLoading(false))
            onStepCompleted()
            navigate(preserveQueryParams('/onboarding/sat-connection', params))
        } catch (err) {
            dispatch(setStepLoading(false))
            console.error('Error submitting form:', err)
            setError('Hubo un problema al guardar los datos. Por favor, intenta de nuevo.')
            message.error('Hubo un problema al guardar los datos. Por favor, intenta de nuevo.')
        }
    }

    const updateContext = () => {
        const updatedValues = form.getFieldsValue()

        const updatedData = {
            ...fiscalInformationOnb,
            ...updatedValues,
            tax_system_label: taxRegimes.find((regime) => regime.value === updatedValues.tax_system)?.label,
        }

        dispatch(
            setData({
                item: 'fiscalInformationOnb',
                data: updatedData,
            }),
        )
    }

    const FiscalInformationDetails = (
        <>
            <Space direction="vertical" style={{ width: '100%' }}>
                <TextInput
                    label="Nombre legal"
                    placeholder="Ingresa tu nombre legal"
                    name="legal_name"
                    onChange={(value: any) => {
                        form.setFieldsValue({
                            legal_name: value.currentTarget.value.toUpperCase(),
                        })
                    }}
                    rules={[{ required: true, message: 'Por favor ingresa el nombre legal' }]}
                />

                <TextInput
                    label="RFC"
                    placeholder="Ingresa tu RFC"
                    name="rfc"
                    onChange={(value: any) => {
                        form.setFieldsValue({
                            rfc: value.currentTarget.value.toUpperCase(),
                        })
                    }}
                    rules={[{ required: true, message: 'Por favor ingresa el RFC' }]}
                />

                <SelectInput
                    label="Régimen Fiscal"
                    name="tax_system"
                    placeholder="Selecciona tu régimen fiscal"
                    rules={[{ required: true, message: 'Por favor ingresa tu régimen fiscal' }]}
                    options={taxRegimes}
                />

                <AddressModal parentForm={form} useGoogleMaps={false} />

                <Col>
                    <label htmlFor="address" className="ant-form-item-label">
                        Dirección
                    </label>
                    {/* {infSource === 'csf' && (
                        <Input
                            value={form.getFieldValue('addressStr')}
                            placeholder="Buscar dirección"
                            style={{ flex: 1, marginTop: '10px' }}
                            readOnly
                        />
                    )} */}
                    <Tooltip title={form.getFieldValue('addressStr') ? form.getFieldValue('addressStr') : ''}>
                        <Row
                            className="btn-outline rounded-md clickable"
                            style={{ padding: '0px 10px', overflow: 'hidden' }}
                            onClick={() => {
                                dispatch(openModal('addressModalVisible'))
                            }}
                        >
                            <Typography.Text
                                className="p-base-regular neutral-3"
                                style={{
                                    flex: 1,
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}
                            >
                                {fiscalInformationOnb?.address?.zip
                                    ? form.getFieldValue('addressStr')
                                    : 'Completa tu dirección'}
                            </Typography.Text>
                        </Row>
                    </Tooltip>
                </Col>

                {/* {infSource === 'csf' && (
                    <>
                        <AddressModal parentForm={form} useGoogleMaps={false} />
                        <Typography.Text className="clickable p-base-bold" onClick={() => setCollapsed(!collapsed)}>
                            Ver detalles de mi dirección {collapsed ? <CaretRight /> : <CaretDown />}
                        </Typography.Text>
                    </>
                )}*}
                {!collapsed && <AddressFormInputLayout disabled={true} />} */}
            </Space>
        </>
    )

    const FiscalInformationForm = (
        <>
            <Row justify="center" gutter={[0, 0]} style={{ marginBottom: '24px' }}>
                <Col span={24}>
                    <Typography className="h3-bold" style={{ margin: 0 }}>
                        Información fiscal
                    </Typography>
                </Col>
            </Row>
            {error && (
                <Alert
                    message="Error"
                    description={error}
                    type="error"
                    closable
                    onClose={() => setError(null)}
                    style={{ marginBottom: '20px' }}
                />
            )}
            <Form
                form={form}
                layout="vertical"
                onFinish={handleFinish}
                onFieldsChange={updateContext}
                onFinishFailed={(errorInfo) => {
                    console.log('Form validation failed:', errorInfo)
                    message.error('Por favor, corrige los errores en el formulario antes de continuar.')
                }}
            >
                <Space direction="vertical" style={{ width: '100%' }}>
                    {infSource === 'manual' && (
                        <Button
                            className="btn-outline"
                            style={{ width: '100%' }}
                            onClick={() => {
                                setInfSource('csf')
                                setShowFiscalForm(false)
                            }}
                        >
                            <ArrowLeft /> Ingresar mi información a partir de mi constancia
                        </Button>
                    )}

                    {infSource !== 'manual' && (
                        <UploadCSFInput
                            loadingInfoRetrieval={loadingInfo}
                            onFiscalLoaded={(fiscalData) => {
                                try {
                                    // dispatch(setStepLoading(true))
                                    setLoadingInfo(true)
                                    if (Object.keys(fiscalData).length <= 0) {
                                        trackEvent({ name: 'onboarding_fiscal_csf_error', metadata: { v: 'v2' } }, posthog)
                                        message.error(
                                            'No pudimos extraer la información de forma correcta, por favor ingresa los datos manualmente.',
                                        )
                                        return
                                    }
                                    const addressStr = `${fiscalData.street || ''} #${fiscalData.numberExt || ''}, ${fiscalData.suburb || ''}, ${fiscalData.city || ''}, ${fiscalData.zipCode || ''} ${fiscalData.state || ''}`
                                    const tax_system_label = taxRegimes.find(
                                        (regime) => regime.value === fiscalData.fiscalRegime,
                                    )?.label

                                    const data = {
                                        rfc: fiscalData.rfc || '',
                                        legal_name: fiscalData.name || '',
                                        tax_system: fiscalData.fiscalRegime || '',
                                        address: {
                                            country: 'MEX',
                                            zip: fiscalData.zipCode || '',
                                            street: fiscalData.street || '',
                                            exterior: fiscalData.numberExt || '',
                                            interior: fiscalData.numberInt || '',
                                            state: fiscalData.state || '',
                                            city: fiscalData.city || '',
                                            neighborhood: fiscalData.suburb || '',
                                        },
                                        addressStr,
                                        tax_system_label,
                                    }

                                    dispatch(
                                        setData({
                                            item: 'fiscalInformationOnb',
                                            data,
                                        }),
                                    )

                                    form.setFieldsValue({
                                        country: 'MEX',
                                        zip: fiscalData.zipCode || '',
                                        street: fiscalData.street || '',
                                        exterior: fiscalData.numberExt || '',
                                        interior: fiscalData.numberInt || '',
                                        state: fiscalData.state || '',
                                        city: fiscalData.city || '',
                                        neighborhood: fiscalData.suburb || '',
                                    })

                                    setInfSource('csf')
                                    setShowFiscalForm(true)
                                    message.success('Información fiscal cargada exitosamente')
                                    // dispatch(setStepLoading(false))
                                    setLoadingInfo(true)
                                } catch (error) {
                                    console.error('Error loading fiscal data:', error)
                                    setError(
                                        'Hubo un problema al cargar los datos fiscales. Por favor, intenta de nuevo.',
                                    )
                                    message.error(
                                        'Hubo un problema al cargar los datos fiscales. Por favor, intenta de nuevo.',
                                    )
                                }
                            }}
                        />
                    )}

                    {!showFiscalForm && (
                        <Button
                            className="btn-outline"
                            style={{ width: '100%' }}
                            onClick={() => {
                                trackEvent({ name: 'onboarding_fiscal_manual_entry', metadata: { v: 'v2' } }, posthog)
                                setInfSource('manual')
                                setShowFiscalForm(true)
                            }}
                        >
                            Ingresar mi información manualmente <ArrowRight />
                        </Button>
                    )}
                    {showFiscalForm && FiscalInformationDetails}
                </Space>
                {renderButtons()}
            </Form>
        </>
    )

    return <StepLayout form={FiscalInformationForm} supportElement={<InvoicePreview invoiceSection="fiscaData" />} />
}
