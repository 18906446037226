import { GetTeamHelper } from '../../context/databaseContextHelpers'
import { getItemsAmounts } from '../../functions/helpers'

export const handleIncomeInvoiceObject = ({
    values,
    invoice,
    client,
    items,
}: {
    values: any
    invoice: any
    client: any
    items: any
}) => {
    const invoiceObject: any = {
        items,
        internalItems: items,
        payment_form: values.paymentForm,
        payment_method: values.paymentMethod,
        exchange: values.exchange,
        exchange_rate: values.exchangeRate,
        exchangeRate: values.exchangeRate,
        use: values.use,
        totalItemsAmounts: getItemsAmounts(items),
        invoicePaid: values?.paymentMethod === 'PUE' ? true : false,
        global: {
            periodicity: values.periodicity,
            months: values.months,
            year: values.year,
        },
        idempotency_key: invoice.idempotency_key ?? null,
        // TODO review case whmcsItems and whmcsInvoiceId
    }

    if (!values.periodicity || !values.months || !values.year) {
        delete invoiceObject.global
    }

    return invoiceObject
}

export const handlePaymentInvoiceObject = ({
    values,
    invoice,
    state,
    client,
}: {
    values: any
    invoice: any
    state: any
    client: any
}) => {
    const invoiceObject: any = {
        complements: [
            {
                type: 'pago',
                data: (invoice?.complements ?? [])?.map((c: any) => c?.data).flat(),
            },
        ],
        idempotency_key: invoice.idempotency_key ?? null,
    }

    if (state.isThirdParty) {
        invoiceObject.third_party = {
            legal_name: values.thirdPartyLegalName,
            tax_id: values.thirdPartyTaxId,
            tax_system: values.thirdPartyTaxSystem,
            zip: values.thirdPartyZip,
        }
    }

    return invoiceObject
}

export const handleEgressInvoiceObject = ({
    values,
    invoice,
    client,
    items,
}: {
    values: any
    invoice: any
    client: any
    items: any
}) => {
    const invoiceObject = {
        items,
        internalItems: items,
        payment_form: values.paymentForm,
        exchange: values.exchange,
        exchange_rate: values.exchangeRate,
        exchangeRate: values.exchangeRate,
        use: values.use,
        related_documents: values.relatedDocuments || [],
        idempotency_key: invoice.idempotency_key ?? null,
    }
    return invoiceObject
}

export const getFolioByInvoiceType = (inv?: any, team?: any, dispatch?: any) => {
    GetTeamHelper(team.id, dispatch)

    const invoice_folio = !inv?.folio_number ? team?.invoice_folio : inv?.folio_number
    const invoice_folio_egress = !inv?.folio_number ? team?.invoice_folio_egress : inv?.folio_number
    const invoice_folio_payments = !inv?.folio_number ? team?.invoice_folio_payments : inv?.folio_number

    return {
        invoice_folio: invoice_folio,
        invoice_folio_egress: invoice_folio_egress,
        invoice_folio_payments: invoice_folio_payments,
    }
}

export const getSerieByInvoiceType = (inv?: any, team?: any, dispatch?: any) => {
    GetTeamHelper(team.id, dispatch)

    const invoice_serie = !inv?.series ? team?.invoice_serie : inv?.series
    const invoice_serie_egress = !inv?.series ? team?.invoice_serie_egress : inv?.series
    const invoice_serie_payments = !inv?.series ? team?.invoice_serie_payments : inv?.series

    return {
        invoice_serie: invoice_serie,
        invoice_serie_egress: invoice_serie_egress,
        invoice_serie_payments: invoice_serie_payments,
    }
}
