import { ArrowRight, ArrowsLeftRight, CheckCircle, LockSimple } from '@phosphor-icons/react'
import { Button, Col, Divider, Form, Modal, Row, Space, Spin, Typography, message } from 'antd'
import { doc, getFirestore, updateDoc } from 'firebase/firestore'
import { usePostHog } from 'posthog-js/react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth } from 'reactfire'
import circlesGif from '../../../assets/images/circles.gif'
import satbw from '../../../assets/images/satbw.png'
import { CSDForm, FiscalAddressForm, FiscalInformationForm } from '../../components/Forms/Integrations/SatIntegration'
import { GetTeamHelper } from '../../context/databaseContextHelpers'
import { SignedInternalAPIRequest } from '../../functions/APIRequest'
import { trackEvent } from '../../analytics/helpers'

interface SatModalProps {
    isOpen: boolean
    onClose: () => void
    onStepCompletion: () => void
}

const SatModalGs = ({ isOpen, onClose, onStepCompletion }: SatModalProps) => {
    const auth = useAuth()
    const fs = getFirestore()
    const posthog = usePostHog()
    const dispatch = useDispatch()

    const { user } = useSelector((state: any) => state.user)
    const { team } = useSelector((state: any) => state.team)
    const { billingAccount } = useSelector((state: any) => state.billingAccount)

    const [step, setStep] = useState(1)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (team?.facturapi?.completed && step === 4) {
            setStep(5)
        }
    }, [team?.facturapi?.completed, step])

    const renderCheckItems = (items: string[]) =>
        items.map((item, index) => (
            <Col span={24} style={{ marginTop: index > 0 ? '10px' : '0' }} key={index}>
                <Typography.Text className="p-base-regular neutral-2">
                    <CheckCircle className="neutral-1" style={{ marginRight: '10px' }} />
                    {item}
                </Typography.Text>
            </Col>
        ))

    const Step1 = () => (
        <>
            <Row>
                <Col span={24}>
                    <img src={circlesGif} width="60px" alt="Gigstack animated circles" />
                    <Row align="middle" style={{ marginTop: '10px' }}>
                        <Typography.Text
                            className="h4-regular neutral-1"
                            style={{ fontFamily: 'Russo One', marginRight: '10px' }}
                        >
                            gigstack
                        </Typography.Text>
                        <ArrowsLeftRight className="neutral-2" size={16} style={{ marginRight: '10px' }} />
                        <img src={satbw} width="12%" alt="Logo del sat" />
                        <Typography.Text className="p-base-regular neutral-3">
                            Comencemos, vamos a terminar con la configuración de tu cuenta para que puedas emitir
                            facturas
                        </Typography.Text>
                    </Row>
                </Col>
            </Row>
            <Row style={{ textAlign: 'left', marginTop: '25px' }}>
                {renderCheckItems([
                    'Factura automáticamente',
                    'Crea complementos de pago y facturas de egreso',
                    'Crea y programa facturas recurrentes',
                ])}
            </Row>
            <Row style={{ marginTop: '50px', marginBottom: '50px' }}>
                <Col span={24}>
                    <Button className="btn-primary" icon={<ArrowRight />} onClick={() => setStep(2)}>
                        Comenzar
                    </Button>
                </Col>
            </Row>
            <Row style={{ textAlign: 'left' }}>
                <Col span={24}>
                    <Divider />
                    <Typography.Text className="p-small-regular neutral-3">
                        <LockSimple className="neutral-3" />
                        Todos los datos son encriptados y almacenados por datos seguros
                    </Typography.Text>
                </Col>
            </Row>
        </>
    )

    const useFormSetFields = (form: any, fields: any) => {
        useEffect(() => {
            form.setFieldsValue(fields)
        })
    }

    const FormStep = ({ title, description, formContent, onFinish }: any) => {
        const [form] = Form.useForm()

        useFormSetFields(form, formContent.initialValues)

        return (
            <>
                <Row gutter={[10, 10]}>
                    <Col span={24}>
                        <Typography.Text className="h4-bold neutral-1">{title}</Typography.Text>
                    </Col>
                    {description && (
                        <Col span={24}>
                            <Typography.Text className="p-base-regular neutral-3">{description}</Typography.Text>
                        </Col>
                    )}
                    <Col span={24}>
                        <Form form={form} layout="vertical" onFinish={onFinish}>
                            {formContent.form}
                            <Form.Item style={{ textAlign: 'right', marginTop: '16px' }}>
                                <Space>
                                    <Button className="btn-secondary" onClick={() => setStep(step - 1)}>
                                        Atrás
                                    </Button>
                                    <Button
                                        disabled={loading}
                                        className={loading ? 'btn-secondary' : 'btn-primary'}
                                        onClick={() => form.submit()}
                                    >
                                        {loading ? <Spin /> : 'Siguiente'}
                                    </Button>
                                </Space>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </>
        )
    }

    const handleFinish = async (values: any, type: string, updateFields: any) => {
        try {
            setLoading(true)
        
            trackEvent({ name: type }, posthog)
            console.log('*** values', values)
            await updateDoc(doc(fs, `teams/${team?.id}`), updateFields)

            GetTeamHelper(team.id, dispatch)
            setStep(step + 1)
            setLoading(false)
        } catch (error: any) {
            message.error(error?.message ?? 'Ocurrió un error al actualizar la información fiscal')
            setLoading(false)
            return
        }
    }

    const stepData = [
        {
            title: 'Información de facturación',
            description: 'Tal cual se muestra en tu constancia de situación fiscal',
            formContent: {
                initialValues: {
                    taxSystem: team?.tax_system ?? billingAccount?.tax_system ?? billingAccount?.taxSystem ?? '601',
                    rfc: team?.rfc ?? billingAccount.rfc,
                    legalName: team?.legal_name ?? billingAccount?.legalName ?? billingAccount?.legal_name ?? '',
                },
                form: <FiscalInformationForm />,
            },
            onFinish: (values: any) =>
                handleFinish(values, 'update_fiscal', {
                    tax_system: values.taxSystem,
                    taxSystem: values.taxSystem,
                    rfc: (values.rfc ?? '').toUpperCase(),
                    legal_name: (values.legalName ?? '').toUpperCase(),
                    legalName: (values.legalName ?? '').toUpperCase(),
                }),
        },
        {
            title: 'Dirección Fiscal',
            description: 'Tal cual se muestra en tu constancia de situación fiscal',
            formContent: {
                initialValues: {
                    street: team?.address?.street ?? billingAccount?.address?.street,
                    exterior: team?.address?.exterior ?? billingAccount?.address?.exterior,
                    interior: team?.address?.interior_number ?? billingAccount?.address?.interior_number,
                    neighborhood: team?.address?.neighborhood ?? billingAccount?.address?.neighborhood,
                    zip: team?.address?.zip ?? billingAccount?.address?.zip,
                    city: team?.address?.city ?? billingAccount?.address?.city,
                    state: team?.address?.state ?? billingAccount?.address?.state,
                },
                form: <FiscalAddressForm />,
            },
            onFinish: (values: any) =>
                handleFinish(values, 'update_fiscal', {
                    address: {
                        street: values.street,
                        exterior: values.exterior ?? '',
                        interior: values.interior ?? '',
                        neighborhood: values.neighborhood,
                        city: values.city,
                        state: values.state,
                        municipality: values.municipality,
                        zip: values.zip,
                    },
                }),
        },
        {
            title: 'Certificado de Sello Digital',
            description: 'Sube tus Certificado de Sello Digital, recuerda que NO es la FIEL',
            formContent: {
                initialValues: {},
                form: <CSDForm error={null} />,
            },
            onFinish: async (values: any) => {
                try {
                    setLoading(true)
                    trackEvent({ name: 'update_csd' }, posthog)
                    const resp: any = await SignedInternalAPIRequest(
                        {
                            password: values.password,
                            rfc: team.rfc,
                            invoicingIntegration: 'facturapi',
                            type: 'connect',
                            typeCsd: 'update',
                        },
                        'invoicing',
                        auth.currentUser,
                    )

                    message.success(resp?.message ?? '¡Listo! Tus sellos están actualizados')
                    setStep(step + 1)
                    setLoading(false)
                } catch (error: any) {
                    message.error(error?.message ?? 'Ocurrió un error al actualizar los sellos')
                    setLoading(false)
                    return
                }
            },
        },
    ]

    const Step5 = () => (
        <>
            <Row>
                <Col span={24}>
                    <img src={circlesGif} width="60px" alt="Gigstack animated circles" />
                    <Row align="middle" style={{ marginTop: '10px' }}>
                        <Typography.Text className="h5-bold neutral-1">
                            {' '}
                            ¡Listo {user?.firstName}, conectaste tu cuenta al SAT!
                        </Typography.Text>
                        <Typography className="h6-regular neutral-3" style={{ marginTop: '10px' }}>
                            Ahora sigamos <span className="h6-bold">con los predeterminados,</span> estos nos ayudarán a
                            facturar automáticamente:
                        </Typography>
                    </Row>
                </Col>
            </Row>
            <Row style={{ textAlign: 'left', marginTop: '25px' }}>
                {renderCheckItems([
                    'Con los impuestos adecuados',
                    'Con la información de los productos',
                    'La facturación global',
                ])}
            </Row>
            <Row style={{ marginTop: '50px', marginBottom: '50px' }}>
                <Col span={24}>
                    <Button className="btn-primary" icon={<ArrowRight />} onClick={onStepCompletion}>
                        Comenzar
                    </Button>
                </Col>
            </Row>
        </>
    )

    const ToRender = () => {
        switch (step) {
            case 1:
                return <Step1 />
            case 2:
            case 3:
            case 4:
                return <FormStep {...stepData[step - 2]} />
            case 5:
                return <Step5 />
            default:
                return <Step1 />
        }
    }

    return (
        <Modal open={isOpen} width="40%" onCancel={onClose} footer={null} className="rounded-md">
            <div style={{ padding: '50px' }}>
                <ToRender />
            </div>
        </Modal>
    )
}

export default SatModalGs
