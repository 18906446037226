import React, { useEffect, useState } from 'react'
import { Input, Select } from 'antd'
import type { SelectProps } from 'antd'
import { MagnifyingGlass } from '@phosphor-icons/react'

// Define the structure of a resource
export interface Vendor {
    id: string
    name: string
    logo: string
    legalName: string
    rfc: string
    supportEmail: string
}

// Define the props for our component
interface SearchableVendorsDropdownProps {
    vendors: Vendor[]
    onSelect: (value: string) => void
    placeholder?: string
    urlVendor: string | null
    disabled?: boolean
}

const SearchableVendorsDropdown: React.FC<SearchableVendorsDropdownProps> = ({
    vendors,
    onSelect,
    placeholder = 'Todas las cuentas',
    urlVendor,
    disabled = false
}) => {
    const [searchValue, setSearchValue] = useState<string>('')
    const [selectedValue, setSelectedValue] = useState<string>('all')

    useEffect(() => {
        setSelectedValue(urlVendor ?? 'all')
    },
    [urlVendor])

    // Filter resources based on search value
    const filteredResources = vendors.filter(
        (vendors) =>
            vendors?.name?.toLowerCase().includes(searchValue?.toLowerCase()) ||
            vendors?.legalName?.toLowerCase().includes(searchValue?.toLowerCase())
    )

    // Convert resources to options format required by Select
    const options: SelectProps['options'] = [
        { value: 'all', label: 'Todas las cuentas' },
        ...filteredResources.map((resource) => ({
            value: resource.id,
            label: resource.name,
        })),
    ]

    // Handle search input change
    const handleSearch = (value: string) => {
        setSearchValue(value)
    }

    // Handle selection of an item
    const handleChange = (value: string) => {
        console.log('value', value)
        setSelectedValue(value)
        onSelect(value)
    }

    // Custom dropdown render to include search input
    const dropdownRender = (menu: React.ReactNode) => (
        <div>
            <Input
                style={{ margin: 8, width: 'auto' }}
                placeholder="Buscar cuentas"
                prefix={<MagnifyingGlass />}
                value={searchValue}
                onChange={(e) => handleSearch(e.target.value)}
                disabled={disabled}
            />
            {menu}
        </div>
    )

    return (
        <Select
            style={{ width: 250 }}
            placeholder={placeholder}
            onChange={handleChange}
            value={selectedValue}
            options={options}
            dropdownRender={dropdownRender}
            optionFilterProp="label"
            filterOption={false}
            showSearch={false}
            notFoundContent={searchValue ? `No results found for "${searchValue}"` : 'No resources available'}
            disabled={disabled}
        />
    )
}

export default SearchableVendorsDropdown
