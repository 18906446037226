import React, { useCallback, useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Input, Row, Spin, Select, Space, Popover, Checkbox } from 'antd'
import { MagnifyingGlass, FunnelSimple, CaretUpDown } from '@phosphor-icons/react'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { SearchIndexComponent } from './IndexComponent'
import { setData } from '../context/dataSlice'
import { TeamDef } from '../../interfaces/teamDef'
import { usePostHog } from 'posthog-js/react'
import { closeModal, openModal } from '../context/modalsSlice'
import { trackEvent } from '../analytics/helpers'

interface SearchProps {
    indexName?: string
    teamId?: string
    type?: string
}

export type CollectionType = 'invoices' | 'clients' | 'payments' | 'receipts' | 'product_keys' | 'unit_keys'

const searchCollections: { value: CollectionType; label: string }[] = [
    { value: 'invoices', label: 'Facturas' },
    { value: 'clients', label: 'Clientes' },
    { value: 'payments', label: 'Pagos' },
    { value: 'receipts', label: 'Recibos' },
]

const collectionFields: Record<CollectionType, { value: string; label: string }[]> = {
    invoices: [
        { value: 'uuid', label: 'UUID' },
        { value: 'objectId', label: 'ID del objeto' },
        { value: 'metadata.orderId', label: 'ID de la orden' },
        { value: 'client.name', label: 'Nombre' },
        { value: 'client.email', label: 'Email' },
        { value: 'client.legal_name', label: 'Nombre legal' },
        { value: 'client.rfc', label: 'RFC' },
        { value: 'folio_number', label: 'Número de folio' },
        { value: 'series', label: 'Serie' },
    ],
    receipts: [
        { value: 'client.name', label: 'Nombre del cliente' },
        { value: 'client.email', label: 'Email' },
        { value: 'client.legal_name', label: 'Nombre legal' },
        { value: 'client.rfc', label: 'RFC' },
        { value: 'objectId', label: 'ID del objeto' },
    ],
    clients: [
        { value: 'name', label: 'Nombre' },
        { value: 'email', label: 'Email' },
        { value: 'rfc', label: 'RFC' },
        { value: 'legal_name', label: 'Nombre legal' },
        { value: 'company', label: 'Compañía' },
    ],
    payments: [
        { value: 'fid', label: 'ID' },
        { value: 'client.name', label: 'Nombre del cliente' },
        { value: 'client.email', label: 'Email' },
        { value: 'whmcsInvoiceId', label: 'ID de WHMCS' },
        { value: 'client.legal_name', label: 'Nombre legal' },
        { value: 'client.rfc', label: 'RFC' },
        { value: 'charge', label: 'ID del cargo' },
    ],
    product_keys: [
        { value: 'code', label: 'Código' },
        { value: 'description', label: 'Descripción' },
        { value: 'type', label: 'Tipo' },
        { value: 'division', label: 'División' },
        { value: 'group', label: 'Grupo' },
    ],
    unit_keys: [
        { value: 'unit', label: 'Unidad' },
        { value: 'name', label: 'Nombre' }
    ],
}

export const getSearchData = async (params: {
    collection: CollectionType
    query: string
    fields?: string[]
    testmode?: boolean
    team: TeamDef
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
}) => {
    const { collection, query, fields, testmode, team, setLoading } = params
    setLoading(true)
    const searchFunction = httpsCallable(getFunctions(), 'searchapp')
    const response = await searchFunction({
        query,
        collection,
        fields,
        apiKey: team?.typesense?.keys?.live_key,
        testmode,
    })
    setLoading(false)
    return response.data
}

const ComponentSearch: React.FC<SearchProps> = () => {
    const dispatch = useDispatch()
    const posthog = usePostHog()

    const { user } = useSelector((state: any) => state.user)
    const { team } = useSelector((state: any) => state.team)
    const { testmode } = useSelector((state: any) => state.data)
    const { searchResultContainerVisible } = useSelector((state: any) => state.modals)

    const [query, setQuery] = useState('')

    const [activeCollection, setActiveCollection] = useState<CollectionType>('invoices')
    const [selectedFields, setSelectedFields] = useState<string[]>([])
    const [loading, setLoading] = useState(false)

    const searchContainerRef = useRef<HTMLDivElement>(null)
    const timeoutRef = useRef<NodeJS.Timeout | null>(null)

    useEffect(() => {
        const defaultCollection = ((user?.interfaceSettings ?? {})[team?.id]?.searchCollection ??
            'invoices') as CollectionType
        setActiveCollection(defaultCollection)
        setSelectedFields(collectionFields[defaultCollection].map((field) => field.value))
    }, [user, team])

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (searchContainerRef.current && !searchContainerRef.current.contains(event.target as Node)) {
                dispatch(closeModal('searchResultContainerVisible'))
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [dispatch])

    const handleSearch = useCallback(
        async (searchQuery: string) => {
            if (searchQuery.length < 1) return

            dispatch(openModal('searchResultContainerVisible'))
            setLoading(true)
            try {
                const data = await getSearchData({
                    query: searchQuery,
                    collection: activeCollection,
                    fields: selectedFields,
                    testmode,
                    team,
                    setLoading,
                })
                trackEvent({
                    name: 'search_performed',
                    metadata: { query: searchQuery, collection: activeCollection, fields: selectedFields },
                }, posthog)
                dispatch(setData({ item: 'searchResult', data }))
                dispatch(
                    setData({
                        item: 'search',
                        data: { type: activeCollection, query: searchQuery, fields: selectedFields },
                    }),
                )
            } catch (error) {
                console.error('Search error:', error)
            } finally {
                setLoading(false)
            }
        },
        [activeCollection, selectedFields, testmode, team, dispatch, posthog],
    )

    const debouncedSearch = useCallback(
        (searchQuery: string) => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current)
            }
            timeoutRef.current = setTimeout(() => {
                handleSearch(searchQuery).catch(console.error)
            }, 300)
        },
        [handleSearch],
    )

    useEffect(() => {
        if (query) {
            debouncedSearch(query)
        }
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current)
            }
        }
    }, [query, debouncedSearch])

    const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === '') {
            dispatch(setData({ item: 'searchResult', data: null }))
            dispatch(setData({ item: 'search', data: null }))
            dispatch(closeModal('searchResultContainerVisible'))
        }
        setQuery(e.target.value)
    }, [dispatch])

    const handleCollectionChange = useCallback(
        async (value: CollectionType) => {
            setActiveCollection(value)
            setSelectedFields(collectionFields[value].map((field) => field.value))
            if (query) await handleSearch(query)
        },
        [query, handleSearch],
    )

    const handleFieldsChange = useCallback(
        async (checkedValues: string[]) => {
            setSelectedFields(checkedValues)
            if (query) await handleSearch(query)
        },
        [query, handleSearch],
    )

    const filterCount = selectedFields.length

    const filterContent = (
        <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
            <Checkbox.Group
                className="vertical-checkbox-group"
                options={collectionFields[activeCollection]}
                value={selectedFields}
                onChange={handleFieldsChange}
            />
        </div>
    )

    return (
        <div className="main-search--container" ref={searchContainerRef}>
            <Space direction="vertical" style={{ width: '100%', marginTop: '15px' }} size="small">
                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        border: '1px solid var(--neutral-4)',
                        borderRadius: '6px',
                    }}
                >
                    <Select
                        defaultValue={activeCollection}
                        style={{
                            width: '100px',
                            borderRadius: 0,
                            borderRight: 'none',
                        }}
                        onChange={handleCollectionChange}
                        options={searchCollections}
                        suffixIcon={<CaretUpDown size={16} />}
                        variant="borderless"
                    />
                    <Popover
                        content={filterContent}
                        title="Seleccionar campos para buscar"
                        trigger="click"
                        placement="bottom"
                    >
                        <Button
                            style={{
                                height: '40px',
                                width: '40px',
                                borderRadius: 0,
                                border: 'none',
                                borderRight: '1px solid var(--neutral-4)',
                                borderLeft: '1px solid var(--neutral-4)',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: 0,
                            }}
                        >
                            <FunnelSimple size={16} />
                            {filterCount > 0 && (
                                <sup
                                    style={{
                                        position: 'absolute',
                                        top: '2px',
                                        right: '2px',
                                        backgroundColor: 'var(--neutral-1)',
                                        color: 'white',
                                        borderRadius: '50%',
                                        padding: '0 4px',
                                        fontSize: '10px',
                                    }}
                                >
                                    {filterCount}
                                </sup>
                            )}
                        </Button>
                    </Popover>
                    <Input.Search
                        variant="borderless"
                        placeholder="Buscar..."
                        style={{ flex: 1 }}
                        value={query}
                        onChange={handleInputChange}
                        onSearch={handleSearch}
                        allowClear
                        enterButton={
                            <Button type="primary">
                                <MagnifyingGlass size={16} />
                            </Button>
                        }
                    />
                </div>

                {searchResultContainerVisible && (
                    <div className="d-flex flex-column" style={{ position: 'relative' }}>
                        <div className="searchResultsContainer">
                            {loading ? (
                                <Row align="middle" justify="center" style={{ margin: '15px' }}>
                                    <Spin />
                                </Row>
                            ) : (
                                <SearchIndexComponent indexName={activeCollection} />
                            )}
                        </div>
                    </div>
                )}
            </Space>
        </div>
    )
}

export default ComponentSearch
