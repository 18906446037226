import React, { useState, useEffect } from 'react'
import { DatePicker, Layout, Select, Space, Typography } from 'antd'
import SearchableVendorsDropdown, { Vendor } from '../components/ComponentIndividual/VendorsSelect'
import { Resource, VendorsResourcesTable } from '../components/Tables/VendorsResourcesTable'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'

const { Content, Header } = Layout
const { RangePicker } = DatePicker

const VendorsPage2: React.FC = () => {
    const [vendors, setVendors] = useState<Vendor[]>([])
    const [selectedVendor, setSelectedVendor] = useState<string | null>(null)
    const [documents, setDocuments] = useState<Resource[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [dateRange, setDateRange] = useState<[number | null, number | null]>([null, null])
    const [documentType, setDocumentType] = useState<string>('all')
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10
    })

    const { team } = useSelector((state: any) => state.team)
    const { testmode } = useSelector((state: any) => state.data)

    const navigate = useNavigate()
    const location = useLocation()

    const callableVendorsFunction = httpsCallable(getFunctions(), 'vendorsCallable')

    const getVendors = async () => {
        setLoading(true)
        const response: any = await callableVendorsFunction({
            action: 'getVendors',
            teamId: team.id,
        })
        setVendors(response.data.data)
        setLoading(false)
    }

    const getDocuments = async () => {
        const getAction = (documentType: string) => {
            switch (documentType) {
                case 'payments':
                    return 'getVendorPayments'
                case 'invoices':
                    return 'getVendorInvoices'
                case 'receipts':
                    return 'getVendorReceipts'
                default:
                    return 'getVendorsResources'
            }
        }

        setLoading(true)
        const response: any = await callableVendorsFunction({
            action: getAction(documentType),
            teamId: team.id,
            filterBy: selectedVendor,
            dates: dateRange,
            pagination: {
                page: pagination.current,
                pageSize: pagination.pageSize,
            },
            livemode: !testmode,
        })
        setDocuments(response.data.data)
        setPagination((prev) => ({ ...prev}))
        setLoading(false)
    }

    const handleResourceSelect = (value: string) => {
        const vendorId = value === 'all' ? null : value
        setSelectedVendor(vendorId)
        setPagination((prev) => ({ ...prev, current: 1 }))
        navigate({
            search: `?resources=${vendorId || 'all'}`
        })
    }

    const handleDateRangeChange = (dates: any, dateStrings: [string, string]) => {
        const [start, end] = dates
        setDateRange([
            start ? start.tz('America/Mexico_City').valueOf() : null,
            end ? end.tz('America/Mexico_City').valueOf() : null,
        ])
        setPagination((prev) => ({ ...prev, current: 1 }))
    }

    const handleDocumentTypeChange = (value: string) => {
        setDocumentType(value)
        setPagination((prev) => ({ ...prev, current: 1 }))
    }

    const handlePageChange = (page: number, pageSize: number) => {
        setPagination((prev) => ({ ...prev, current: page, pageSize: pageSize }))
    }

    useEffect(() => {
        getVendors()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const params = new URLSearchParams(location.search)
        const resources = params.get('resources')
        if (resources && resources !== 'all') {
            setSelectedVendor(resources)
        } else {
            setSelectedVendor(null)
        }
        getDocuments()
        // eslint-disable-next-line
    }, [selectedVendor, dateRange, documentType, pagination.current, pagination.pageSize, location.search])

    return (
        <Layout>
            <Header style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography.Text className="h2-bold neutral-1" style={{ marginBottom: '15px' }}>
                    Proveedores
                </Typography.Text>
            </Header>

            <Content style={{ padding: '24px' }}>
                <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '75%' }}>
                        <SearchableVendorsDropdown
                            vendors={vendors}
                            onSelect={handleResourceSelect}
                            placeholder="Select a project"
                            urlVendor={selectedVendor}
                            disabled={loading}
                        />
                        <RangePicker onChange={handleDateRangeChange} disabled={loading}/>
                        <Select
                            style={{ width: 200 }}
                            defaultValue="all"
                            onChange={handleDocumentTypeChange}
                            options={[
                                { value: 'all', label: 'Todos los documentos' },
                                { value: 'payments', label: 'Pagos' },
                                { value: 'receipts', label: 'Recibos' },
                                { value: 'invoices', label: 'Facturas' },
                            ]}
                            disabled={loading}
                        />
                    </div>
                    <VendorsResourcesTable
                        documents={documents}
                        loading={loading}
                        current={pagination.current}
                        pageSize={pagination.pageSize}
                        onPageChange={handlePageChange}
                    />
                </Space>
            </Content>
        </Layout>
    )
}

export default VendorsPage2
