import React from 'react'
import { Modal, Space, Typography } from 'antd'
import { Resource, setResourceBadge, setStatusBadge } from './VendorsResourcesTable'
import { ElementFromTag } from '../UI/ElementFrom'
import moment from 'moment'
import { CopyableElementId } from '../UI/CopyableElementId'
import { invoiceUsage } from '../../functions/helpers'
import { paymentForms, paymentMethods } from '../../datasets/Fiscal'

interface VendorResourceModalProps {
    isOpen: boolean
    onClose: () => void
    resource: Resource | null
}

const translateKey = (key: string) => {
    switch (key) {
        case 'id':
            return 'ID'
        case 'uuid':
            return 'UUID'
        case 'folio':
            return 'Folio'
        case 'series':
            return 'Serie'
        case 'currency':
            return 'Moneda'
        case 'timestamp':
            return 'Fecha'
        case 'use':
            return 'Uso'
        case 'rate':
            return 'Tipo de cambio'
        case 'payment_form':
            return 'Forma de pago'
        case 'payment_method':
            return 'Método de pago'
        case 'total':
            return 'Total'
        case 'amount':
            return 'Monto'
        case 'from':
            return 'De'
        case 'status':
            return 'Estado'
        case 'legalName':
            return 'Razón social'
        case 'name':
            return 'Nombre'
        case 'zip': 
            return 'Código postal'
        case 'country':
            return 'País'
        default:
            return key
    }
}

export const VendorResourceModal: React.FC<VendorResourceModalProps> = ({ isOpen, onClose, resource }) => {
    if (!resource) return null

    const renderValue = (key: string, value: any) => {
        if (key === 'id') {
            return null
        }
        if (value === undefined || value === null) return null
        if (key === 'from') {
            return <ElementFromTag record={resource} recordType={resource.resourceType} />
        }
        if (key === 'timestamp') {
            return (
                <Typography.Text className="p-base-regular">
                    {moment(value).format('DD MMM YYYY HH:mm')}
                </Typography.Text>
            )
        }
        if (key === 'total' || key === 'amount') {
            const displayTotal = key === 'total' ? value : value / 100
            return (
                <Typography.Text className="p-base-regular">
                    {displayTotal.toLocaleString('en-US', { style: 'currency', currency: resource.currency })}
                </Typography.Text>
            )
        }
        if (key === 'status') {
            return setStatusBadge(resource)
        }
        if (key === 'use') {
            return (
                <Typography.Text className="p-base-regular">
                    {invoiceUsage?.filter((u) => u.value === value)[0]?.label ?? ''}
                </Typography.Text>
            )
        }
        if (key === 'payment_method') {
            return (
                <Typography.Text className="p-small-regular">
                    {paymentMethods?.filter((u) => u.value === value)[0]?.label ?? ''}
                </Typography.Text>
            )
        }
        if (key === 'payment_form') {
            return (
                <Typography.Text className="p-base-regular">
                    {paymentForms.find((d) => d.value === value)?.label}{' '}
                </Typography.Text>
            )
        }
        return <Typography.Text className="p-base-regular">{value}</Typography.Text>
    }

    const renderSection = (title: string, data: Record<string, any>) => (
        <div>
            <Typography.Title level={5} className="p-large-bold">
                {title}
            </Typography.Title>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '8px' }}>
                {Object.entries(data).map(([key, value]) => {
                    if (value === undefined || value === null || value === '') return null
                    return (
                        <div key={key}>
                            {
                                <Typography.Text type="secondary" className="p-base-regular">
                                    {translateKey(key)}:&nbsp;
                                </Typography.Text>
                            }
                            <Typography.Text className="p-base-regular">{renderValue(key, value)}</Typography.Text>
                        </div>
                    )
                })}
            </div>
        </div>
    )

    const displayId = resource.uuid ? resource.uuid : resource.id

    return (
        <Modal
            title={`Detalles del ${setResourceBadge(resource.resourceType)}`}
            open={isOpen}
            onCancel={onClose}
            footer={null}
            width={700}
        >
            {displayId && <CopyableElementId id={displayId} idName="id" />}
            <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                {renderSection('Información del documento', {
                    status: resource.status,
                    folio: resource.folio,
                    series: resource.series,
                    currency: resource.currency,
                    timestamp: resource.timestamp,
                    use: resource.use,
                    rate: resource.rate,
                    payment_form: resource.payment_form,
                    payment_method: resource.payment_method,
                    total: resource.total,
                    amount: resource.amount,
                    from: resource.from,
                })}

                {resource.client &&
                    renderSection('Cliente', {
                        name: resource.client.name,
                        legalName: resource.client.legalName,
                        email: resource.client.email,
                        country: resource.client.country,
                        zip: resource.client.zip,
                    })}

                {resource.team &&
                    renderSection('Cuenta', {
                        name: resource.team.name,
                        rfc: resource.team.rfc,
                        legalName: resource.team.legalName,
                    })}
            </Space>
        </Modal>
    )
}
