import { Button, Dropdown, Row, message } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { setData } from '../../context/dataSlice'
import { ElementRelations } from './ElementRelations'
import { openModal } from '../../context/modalsSlice'
import { usePermissions } from '../../customHooks/usePermissions'
import { PlusCircle } from '@phosphor-icons/react'
import { MenuProps } from 'antd'
import { colors } from '../../designSystem/colors'
import { doc, getDoc, getFirestore } from 'firebase/firestore'

const RelationInvoice = ({ record }: { record: any }) => {
    const dispatch = useDispatch()

    const { canCreatePayment, canCreateInvoice } = usePermissions()
    const { clients } = useSelector((state: any) => state.data)

    // const menu2 = (
    //     <Menu>
    //         <Menu.Item key="1" onClick={() => {

    //             if (!canCreateInvoice || !canCreatePayment) return message.warning('No tienes permisos para crear facturas')

    //             dispatch(setData({
    //                 item: 'client',
    //                 data: record.client
    //             }))
    //             dispatch(setData({
    //                 item: 'selectedServices',
    //                 data: record?.items
    //             }))
    //             dispatch(setData({
    //                 item: 'invoice',
    //                 data: {
    //                     payments: [record?.id],

    //                 }
    //             }))

    //             dispatch(openModal('invoiceModalVisible'))
    //         }}>Emitir factura</Menu.Item>
    //         <Menu.Item key="2" onClick={() => {

    //             if (!canCreateInvoice || !canCreatePayment) return message.warning('No tienes permisos para crear facturas')

    //             dispatch(setData({
    //                 item: 'relations',
    //                 data: {
    //                     element: record?.id,
    //                     type: 'invoices',
    //                     relatedTo: 'payments'
    //                 }

    //             }))
    //             dispatch(openModal('relatedInvoicesModalVisible'))
    //         }}>Asociar Factura</Menu.Item>
    //     </Menu>
    // );

    const items: MenuProps['items'] = [
        {
            key: 'issueInvoice',
            label: 'Emitir factura',
            onClick: async () => {

                const clientId = record?.client?.id || record?.clientId || record?.clientID
                
                const ctxClient = clients.find(
                    (c: any) => c.id === clientId,
                )
                if (!canCreateInvoice || !canCreatePayment)
                    return message.warning('No tienes permisos para crear facturas')
                
                message.loading('Cargando información...')
                
                let clientFromDb
                try {
                    clientFromDb = await getDoc(doc(getFirestore(), 'clients', clientId))
                    
                } catch (error) {
                    clientFromDb = null
                }

                dispatch(
                    setData({
                        item: 'client',
                        data: clientFromDb?.data() || ctxClient || record?.client,
                    }),
                )
                dispatch(
                    setData({
                        item: 'selectedServices',
                        data: record?.items,
                    }),
                )
                dispatch(
                    setData({
                        item: 'invoice',
                        data: {
                            payments: [record?.id],
                            paymentForm: record?.payment_form || record?.paymentForm,
                            paymentMethod: record?.payment_method || record?.paymentMethod || 'PUE',
                            currency: record?.currency,
                            exchange_rate: record?.exchange_rate || record?.exchangeRate || 1,
                        },
                    }),
                )
                dispatch(openModal('invoiceModalVisible'))
            },
        },
        {
            key: 'associateInvoice',
            label: 'Asociar Recurso',
            onClick: () => {
                if (!canCreateInvoice || !canCreatePayment)
                    return message.warning('No tienes permisos para crear facturas')
                dispatch(
                    setData({
                        item: 'relating',
                        data: {
                            element: record,
                            type: 'invoices',
                            collection: 'payments',
                        },
                    }),
                )
                dispatch(
                    setData({
                        item: 'search',
                        data: {
                            type: 'invoices',
                            query: '',
                        },
                    }),
                )
                // dispatch(openModal('relatedInvoicesModalVisible'))
            },
        },
    ]

    if (record.status === 'succeeded' && !record?.invoices?.length && !record?.receipts?.length) {
        items.push({
            key: 'startAutoinvoiceProcess',
            label: 'Iniciar proceso de auto-factura',
            onClick: () => {
                if (!canCreateInvoice || !canCreatePayment)
                    return message.warning('No tienes permisos para iniciar el proceso de auto-factura')
                dispatch(
                    setData({
                        item: 'payment',
                        data: { ...record },
                    }),
                )
                dispatch(openModal('autoinvoiceProcessModalVisible'))
            },
        })
    }

    return (
        <Row justify="center">
            {(record.invoices ?? []).length === 0 && (record.receipts ?? []).length === 0 ? (
                <Dropdown menu={{ items }} placement="bottomLeft" trigger={['click']}>
                    <Button type="link">
                        <PlusCircle size={18} weight="regular" color={colors.primary} />
                    </Button>
                </Dropdown>
            ) : (
                <ElementRelations
                    record={record}
                    elementType="payments"
                    incomingMenu={
                        <Dropdown menu={{ items }} placement="bottomLeft" trigger={['click']}>
                            <PlusCircle size={20} className="icon" style={{ marginLeft: '5px' }} />
                        </Dropdown>
                    }
                />
            )}
        </Row>
    )
}
export default RelationInvoice
